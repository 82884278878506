import { takeEvery } from 'redux-saga/effects';
import {
  CATALOG_FETCH_REQUESTED,
  FILTERS_FETCH_REQUESTED,
  INSTALLER_FETCH_REQUESTED,
  SPECS_REQUESTED,
  AREAS_FETCH_REQUESTED,
  MODULES_FETCH_REQUESTED,
  ROLES_FETCH_REQUESTED,
  RENTAL_STATUSES_FETCH_REQUESTED,
  RENTAL_FILTER_FETCH_REQUESTED,
  FETCH_CATALOG_TABLE_REQUESTED,
  CREATE_CATALOG_RECORD,
  UPDATE_CATALOG_RECORD,
  FETCH_LINE_TABLE_REQUESTED,
} from './actions-types';
import {
  fetchCatalogSaga,
  fetchFiltersSaga,
  fetchInstallerSaga,
  getSpecsSaga,
  fetchAreasSaga,
  fetchRolesSaga,
  fetchModulesSaga,
  fetchRentalStatusesSaga,
  fetchRentalFilterSaga,
  fetchCatalogTableSaga,
  fetchLineTableSaga,
  createCatalogRecordSaga,
  updateCatalogRecordSaga,
} from './sagas';

export default function* watchers() {
  yield takeEvery(FETCH_CATALOG_TABLE_REQUESTED, fetchCatalogTableSaga);
  yield takeEvery(CREATE_CATALOG_RECORD, createCatalogRecordSaga);
  yield takeEvery(UPDATE_CATALOG_RECORD, updateCatalogRecordSaga);
  yield takeEvery(FETCH_LINE_TABLE_REQUESTED, fetchLineTableSaga);
  yield takeEvery(CATALOG_FETCH_REQUESTED, fetchCatalogSaga);
  yield takeEvery(FILTERS_FETCH_REQUESTED, fetchFiltersSaga);
  yield takeEvery(INSTALLER_FETCH_REQUESTED, fetchInstallerSaga);
  yield takeEvery(SPECS_REQUESTED, getSpecsSaga);
  yield takeEvery(AREAS_FETCH_REQUESTED, fetchAreasSaga);
  yield takeEvery(ROLES_FETCH_REQUESTED, fetchRolesSaga);
  yield takeEvery(MODULES_FETCH_REQUESTED, fetchModulesSaga);
  yield takeEvery(RENTAL_STATUSES_FETCH_REQUESTED, fetchRentalStatusesSaga);
  yield takeEvery(RENTAL_FILTER_FETCH_REQUESTED, fetchRentalFilterSaga);
}
