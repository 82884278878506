export const CATALOGS_MODULE = 'CATALOGS_MODULE';

export const CATALOGS = 'CATALOGS';
export const VIEW_CATALOG = 'VIEW_CATALOG';
export const VIEW_CATALOG_BRAND = 'VIEW_CATALOG_BRAND';
export const VIEW_CATALOG_LINE = 'VIEW_CATALOG_LINE';
export const VIEW_CATALOG_VERSION = 'VIEW_CATALOG_VERSION';
export const CREATE_CATALOG_BRAND = 'CREATE_CATALOG_BRAND';
export const CREATE_CATALOG_LINE = 'CREATE_CATALOG_LINE';
export const CREATE_CATALOG_VERSION = 'CREATE_CATALOG_VERSION';
export const EDIT_CATALOG_BRAND = 'EDIT_CATALOG_BRAND';
export const EDIT_CATALOG_LINE = 'EDIT_CATALOG_LINE';
export const EDIT_CATALOG_VERSION = 'EDIT_CATALOG_VERSION';
