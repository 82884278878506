import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { fetchCatalogTable, setCatalogPage } from 'store/catalogs/actions';
import Version from './Version';

export default compose(
  connect(
    (state) => ({
      params: state.catalogs.catalogParams,
      listVersions: state.catalogs.versionTable.list,
      loader: state.commons.loader,
      confirmationMessage: false,
    }),
    {
      fetchCatalogTable,
      setCatalogPage,
    }
  )
)(Version);
