import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { fetchCatalogTable, setCatalogPage } from 'store/catalogs/actions';
import Brand from './Brand';

export default compose(
  connect(
    (state) => ({
      params: state.catalogs.catalogParams,
      listBrands: state.catalogs.brandTable.list,
      loader: state.commons.loader,
      confirmationMessage: false,
    }),
    {
      fetchCatalogTable,
      setCatalogPage,
    }
  )
)(Brand);
