import { useEffect } from 'react';
import Brand from './Brand';
import Line from './Line';
import Version from './Version';
import i18n from '@i18n';
import { TabView, TabPanel } from 'primereact/tabview';
import { useState } from 'react';
import {
  VIEW_CATALOG_BRAND,
  VIEW_CATALOG_LINE,
  VIEW_CATALOG_VERSION,
} from 'utils/roles/permissions/catalogsPermissions';
import { validateRolePermissions } from 'utils/roles';

const CatalogsMain = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    if (validateRolePermissions(VIEW_CATALOG_BRAND)) return;
    else if (validateRolePermissions(VIEW_CATALOG_LINE)) setActiveIndex(1);
    else if (validateRolePermissions(VIEW_CATALOG_VERSION)) setActiveIndex(2);
  }, []);
  return (
    <TabView
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    >
      {validateRolePermissions(VIEW_CATALOG_BRAND) && (
        <TabPanel header={i18n('COMMONS__BRAND')}>
          <Brand />
        </TabPanel>
      )}
      {validateRolePermissions(VIEW_CATALOG_LINE) && (
        <TabPanel header={i18n('COMMONS__LINE')}>
          <Line />
        </TabPanel>
      )}
      {validateRolePermissions(VIEW_CATALOG_VERSION) && (
        <TabPanel header={i18n('COMMONS__VERSION')}>
          <Version />
        </TabPanel>
      )}
    </TabView>
  );
};

export default CatalogsMain;
