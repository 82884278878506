import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import LineModal from './LineModal';
import {
  fetchCatalog,
  selectCategory,
  closeResponseDialog,
} from 'store/catalogs/actions';

export default compose(
  connect(
    (state) => {
      const typesBySelectedCategory = state.catalogs?.categorySelected?.types;
      return {
        loader: state.commons.loader,
        listBrands: state.catalogs.brand.list,
        categorySelected: state.catalogs.categorySelected,
        listCategory: _get(state, 'catalogs.vehicleCategory.list', []),
        responseDialog: state.catalogs.responseDialog,
        typesBySelectedCategory,
      };
    },
    { fetchCatalog, selectCategory, closeResponseDialog }
  )
)(LineModal);
