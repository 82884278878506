import React, { useEffect, useState, useCallback } from 'react';
import {
  TableNavigation,
  Button,
  Table,
  withForm,
} from '@casanova/casanova-common';
import columns, { contextualMenu, getCatalogContextualMenu } from './columns';
import { transformDropdownElements } from 'utils/transformHelpers';
import { config } from './config';
import i18n from '@i18n';
import _isEmpty from 'lodash/isEmpty';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import VersionModal from '../modals/VersionModal';
import { CREATE_CATALOG_VERSION } from 'utils/roles/permissions/catalogsPermissions';
import { RoleVerifier } from 'components';

const Version = ({
  fetchCatalogTable,
  listVersions,
  loader,
  onUpdateForm,
  setCatalogPage,
  type = 'version',
}) => {
  const { content, params } = listVersions;

  const [action, setAction] = useStateWithCallbackLazy(FORM_ACTIONS.EDIT);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDropdownSelect = useCallback(
    (section) => {
      const { name = '', uuid = '' } = section;
      switch (name) {
        case (contextualMenu.EDIT, name):
          const version = content.find((b) => b.uuid === uuid);
          setAction(FORM_ACTIONS.EDIT);
          setSelectedVersion(version);
          setIsModalOpen(true);
          break;
        default:
          break;
      }
    },
    [content]
  );

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedVersion(null);
  };

  const handleSuccess = () => {
    setIsModalOpen(false);
    setSelectedVersion(null);
    fetchCatalogTable({ type, params });
  };

  useEffect(() => {
    fetchCatalogTable({ type, params });
  }, [fetchCatalogTable, type, params]);

  useEffect(() => {
    if (!_isEmpty(params)) {
      onUpdateForm({ ...params, type });
    }
  }, [onUpdateForm]);

  const handleAddClick = useCallback(() => {
    setAction(FORM_ACTIONS.ADD);
    setIsModalOpen(true);
  }, [history]);

  return (
    <>
      <VersionModal
        action={action}
        type={type}
        show={isModalOpen}
        setShow={setIsModalOpen}
        onClose={handleModalClose}
        onSuccess={handleSuccess}
        selectedVersion={selectedVersion}
      />
      <div>
        <Table
          additionalHeader
          rowClassName="row-clickable"
          emptyMessage="No se encontraron marcas"
          dataList={content}
          columns={columns.map((col) => ({
            ...col,
            renderer: (props = { data: {} }) => {
              const { status } = props.data;
              const Component = col.renderer;
              return (
                <Component
                  {...props}
                  dropdownOptions={transformDropdownElements({
                    elements: getCatalogContextualMenu(),
                  })}
                />
              );
            },
          }))}
          dropdownCell
          dropdownOptions={transformDropdownElements({
            elements: getCatalogContextualMenu(),
          })}
          onDropdownSelect={handleDropdownSelect}
          loading={loader}
        />
        <div className="row">
          <div className="col-12 col-xl-7 table-paginator text-right d-flex justify-content-between align-items-center">
            <TableNavigation
              search={listVersions}
              onChange={(page) => setCatalogPage({ type, page })}
            />
          </div>
          <div className="col-12 col-md-8 col-xl-5">
            <RoleVerifier identifier={CREATE_CATALOG_VERSION}>
              <Button block onClick={handleAddClick}>
                {i18n('COMMONS__NEW')}
              </Button>
            </RoleVerifier>
          </div>
        </div>
      </div>
    </>
  );
};
const ListVersion = withForm({ config })(Version);

export default ListVersion;
