import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { useState, useEffect } from 'react';

import {
  numericMask,
  alphaMask,
} from '@casanova/casanova-common/lib/utils/masks';

import i18n from '@i18n';
import { AdditionalHeaderCamp } from 'components';
import VERSIONS from 'utils/constants/versions';
import { setListOfContractsParams } from 'store/contracts/actions';
import { number2mxn } from 'utils/currency';
import { getBussinesAreaList } from 'services/contracts/index.js';

import { CellText } from 'components/ColumnsCells';

const getList = async () => {
  const bussinesList = await getBussinesAreaList();
  const rest = bussinesList.content.filter(
    (e) => e.identifier != 'DAILY_RENTAL'
  );
  const filter = rest.map((e) => {
    return {
      value: e.uuid,
      label: e.name,
    };
  });
  return filter;
};

export default [
  {
    label: 'Folio',
    field: 'folio',
    align: 'center',
    renderer: (props) => <CellText {...props} />,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="folio"
        searchKey="folio"
        // mask={alphaMask(10)}
        mask={numericMask(10)}
      />
    ),
  },
  {
    label: 'No. de contrato',
    field: 'noContrato',
    align: 'center',
    renderer: ({ value, ...props }) => (
      <CellText value={value || 'Sin No. de contrato'} {...props} />
    ),
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp name="noContrato" searchKey="noContrato" />
    ),
  },
  {
    label: i18n('CONTRACTS__CONTRACT_DETAIL__START_DATE'),
    field: 'startDate',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();
      return (
        <AdditionalHeaderCamp
          name="startDate"
          searchKey="startDate"
          mask={numericMask(10)}
          type="DATE"
          version={VERSIONS.V2}
          datePickerProps={{
            resetDate: Boolean(values.startDate),
            onResetDate: () => {
              setFieldValue('startDate', '');
              dispatch(setListOfContractsParams({ startDate: '' }));
            },
          }}
          dateProps={{
            minimumDate: 'contracts.list.filters.minStartDate',
            maximumDate: 'contracts.list.filters.maxStartDate',
          }}
        />
      );
    },
  },
  {
    label: i18n('CONTRACTS__CONTRACT_DETAIL__END_DATE'),
    field: 'endDate',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();
      return (
        <AdditionalHeaderCamp
          name="endDate"
          searchKey="endDate"
          mask={numericMask(10)}
          type="DATE"
          version={VERSIONS.V2}
          datePickerProps={{
            resetDate: Boolean(values.endDate),
            onResetDate: () => {
              setFieldValue('endDate', '');
              dispatch(setListOfContractsParams({ endDate: '' }));
            },
          }}
          dateProps={{
            minimumDate: 'contracts.list.filters.minEndDate',
            maximumDate: 'contracts.list.filters.maxEndDate',
          }}
        />
      );
    },
  },
  {
    label: i18n('CONTRACTS__CONTRACT_DETAIL__CUSTOMER', ['']),
    field: 'juridicalPersonName',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="juridicalPersonName"
        searchKey="juridicalPersonName"
      />
    ),
  },
  // {
  //   label: i18n('CONTRACTS__CONTRACT_DETAIL__BUSINESS_UNIT', ['']),
  //   field: 'businessArea',
  //   align: 'center',
  //   renderer: CellText,
  //   AdditionalHeaderCamp: () => {
  //     const [businessAreaOptions, setBusinessAreaOptions] = useState([]);
  //     const { setFieldValue } = useFormikContext();

  //     // Cargar la lista de opciones asíncronamente
  //     useEffect(() => {
  //       const fetchData = async () => {
  //         const options = await getList(); // Llamada a la función para obtener datos
  //         setBusinessAreaOptions(options);
  //       };
  //       fetchData();
  //     }, []);

  //     return (
  //       <AdditionalHeaderCamp
  //         name="businessArea"
  //         searchKey="businessArea"
  //         options={businessAreaOptions} // Asignar las opciones cargadas
  //         selectProps={{ fullKey: true, availableFirstValue: true }}
  //         type="SELECT"
  //       />
  //     );
  //   },
  // },
  {
    label: i18n('CONTRACTS__CONTRACT_DETAIL__BUSINESS_UNIT', ['']),
    field: 'businessArea',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const [businessAreaOptions, setBusinessAreaOptions] = useState([]);
      const { setFieldValue } = useFormikContext();

      // Cargar la lista de opciones asíncronamente
      useEffect(() => {
        const fetchData = async () => {
          const bussinesList = await getBussinesAreaList(); // Llama al servicio
          const filteredList = bussinesList.content.filter(
            (item) => item.isInContractVirtual // Filtra solo `isInContractVirtual: true`
          );
          const options = filteredList.map((item) => ({
            value: item.uuid,
            label: item.name,
          }));
          setBusinessAreaOptions(options);
        };
        fetchData();
      }, []);

      return (
        <AdditionalHeaderCamp
          name="businessArea"
          searchKey="businessArea"
          options={businessAreaOptions} // Usa las opciones filtradas
          selectProps={{ fullKey: true, availableFirstValue: true }}
          type="SELECT"
        />
      );
    },
  },

  {
    label: 'Monto Máximo',
    field: 'maxAmount',
    align: 'center',
    renderer: ({ value, ...props }) => (
      <CellText value={number2mxn(value || 0)} {...props} />
    ),
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="maxAmount"
        searchKey="maxAmount"
        type="number"
      />
    ),
  },
  {
    label: 'Monto Mínimo',
    field: 'minAmount',
    align: 'center',
    renderer: ({ value, ...props }) => (
      <CellText value={number2mxn(value || 0)} {...props} />
    ),
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="minAmount"
        searchKey="minAmount"
        type="number"
      />
    ),
  },
  {
    label: i18n('CONTRACTS__CONTRACT_DETAIL__VEHICLES_NUMBER', ['']),
    field: 'includedVehicles',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="includedVehicles"
        searchKey="includedVehicles"
        mask={numericMask(5)}
      />
    ),
  },
  {
    label: 'Sucursal',
    field: 'branch',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        // mask={alphaMaskWithSpaces(20)}
        name="branch"
        searchKey="branch"
        options="commons.branches"
        selectProps={{ availableFirstValue: true }}
        type="SELECT"
      />
    ),
  },
  {
    label: i18n('CONTRACTS__CONTRACT_DETAIL__MANAGER', ['']),
    field: 'manager',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp name="manager" searchKey="manager" />
    ),
  },
  {
    label: i18n('CONTRACTS__CONTRACT_DETAIL__ADMIN_VEHICLES', ['']),
    field: 'vehiclesAdmin',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp name="vehiclesAdmin" searchKey="vehiclesAdmin" />
    ),
  },
  {
    label: i18n('CONTRACTS__CONTRACT_DETAIL__COORDINATOR', ['']),
    field: 'coordinator',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp name="coordinator" searchKey="coordinator" />
    ),
  },
];
