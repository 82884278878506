import { takeEvery, takeLatest } from 'redux-saga/effects';
import { downloadTemplateForMassiveLoad } from '../../services/vehicles';
import {
  VEHICLES_FETCH_ALL,
  VEHICLES_FETCH_REQUESTED,
  VEHICLES_FETCH_FILTER,
  SAVE_VEHICLE_REQUESTED,
  UPLOAD_VEHICLES,
  DOWNLOAD_FAILED_RECORDS,
  DOWNLOAD_MASSIVE_CHANGE_FAILED_RECORDS,
  FETCH_VEHICLE_INFO_REQUESTED,
  EDIT_VEHICLE_REQUESTED,
  EXPORT_CATALOG_RECORDS,
  EXPORT_VEHICLE_DETAIL,
  DOWNLOAD_TEMPLATE_MASSIVE_LOAD,
  DOWNLOAD_TEMPLATE_MASSIVE_CHANGE,
  SAVE_ADDITIONAL,
  GET_ADDITIONAL,
  DOWNLOAD_ADDITIONAL_FILE,
  GET_TYPES,
  EDIT_VEHICLE_DETAILS_REQUESTED,
  VEHICLE_STATUS_REQUESTED,
  VEHICLE_CONTRACTS_REQUESTED,
  VEHICLE_LOANS_REQUESTED,
  VEHICLE_TRANSFERS_REQUESTED,
  FETCH_HISTORIC_DETAILS_REQUESTED,
  FETCH_VEHICLE_DETAILS_REQUESTED,
  MARK_VEHICLE_FOR_SALE_REQUESTED,
  SEARCH_VEHICLES,
  VEHICLE_VIRTUAL_CONTRACTS_REQUESTED,
  UPLOAD_MASSIVE_CHANGE,
} from './actions-types';
import {
  fetchVehiclesSaga,
  fetchVehiclesFiltersSaga,
  saveVehicleSaga,
  uploadVehiclesSaga,
  uploadMassiveChangeSaga,
  downloadMassiveChangeTemplateSaga,
  downloadFailedRecordsSaga,
  downloadMassiveChangeFailedRecordsSaga,
  fetchVehiclesInfoSaga,
  editVehicleSaga,
  exportVehiclesSaga,
  exportVehicleDetailSaga,
  getAdditionalSaga,
  saveAdditionalSaga,
  downloadAdditionalFileSaga,
  getTypesSaga,
  vehicleStatusSaga,
  vehicleContractsSaga,
  vehicleLoansSaga,
  vehicleTransfersSaga,
  fetchHistoricDetailsSaga,
  editVehicleDetailSaga,
  fetchVehicleDetailSaga,
  markVehicleForSaleSaga,
  getVehiclesByPlateSaga,
  vehicleVirtualContractsSaga,
} from './sagas';

export default function* watchers() {
  yield takeLatest(VEHICLES_FETCH_REQUESTED, fetchVehiclesSaga);
  yield takeLatest(VEHICLES_FETCH_FILTER, fetchVehiclesFiltersSaga);
  yield takeLatest(SAVE_VEHICLE_REQUESTED, saveVehicleSaga);
  yield takeLatest(UPLOAD_VEHICLES, uploadVehiclesSaga);
  yield takeLatest(UPLOAD_MASSIVE_CHANGE, uploadMassiveChangeSaga);
  yield takeLatest(DOWNLOAD_FAILED_RECORDS, downloadFailedRecordsSaga);
  yield takeLatest(
    DOWNLOAD_MASSIVE_CHANGE_FAILED_RECORDS,
    downloadMassiveChangeFailedRecordsSaga
  );
  yield takeLatest(EXPORT_CATALOG_RECORDS, exportVehiclesSaga);
  yield takeLatest(EXPORT_VEHICLE_DETAIL, exportVehicleDetailSaga);
  yield takeLatest(
    DOWNLOAD_TEMPLATE_MASSIVE_LOAD,
    downloadTemplateForMassiveLoad
  );
  yield takeLatest(
    DOWNLOAD_TEMPLATE_MASSIVE_CHANGE,
    downloadMassiveChangeTemplateSaga
  );

  yield takeLatest(FETCH_VEHICLE_INFO_REQUESTED, fetchVehiclesInfoSaga);

  yield takeLatest(EDIT_VEHICLE_REQUESTED, editVehicleSaga);
  yield takeEvery(GET_ADDITIONAL, getAdditionalSaga);
  yield takeEvery(SAVE_ADDITIONAL, saveAdditionalSaga);
  yield takeEvery(DOWNLOAD_ADDITIONAL_FILE, downloadAdditionalFileSaga);
  yield takeEvery(GET_TYPES, getTypesSaga);
  yield takeEvery(VEHICLE_STATUS_REQUESTED, vehicleStatusSaga);
  yield takeEvery(VEHICLE_CONTRACTS_REQUESTED, vehicleContractsSaga);
  yield takeEvery(
    VEHICLE_VIRTUAL_CONTRACTS_REQUESTED,
    vehicleVirtualContractsSaga
  );
  yield takeEvery(VEHICLE_LOANS_REQUESTED, vehicleLoansSaga);
  yield takeEvery(VEHICLE_TRANSFERS_REQUESTED, vehicleTransfersSaga);
  yield takeEvery(FETCH_HISTORIC_DETAILS_REQUESTED, fetchHistoricDetailsSaga);
  yield takeEvery(EDIT_VEHICLE_DETAILS_REQUESTED, editVehicleDetailSaga);
  yield takeEvery(FETCH_VEHICLE_DETAILS_REQUESTED, fetchVehicleDetailSaga);
  yield takeEvery(MARK_VEHICLE_FOR_SALE_REQUESTED, markVehicleForSaleSaga);
  yield takeEvery(SEARCH_VEHICLES, getVehiclesByPlateSaga);
}
