export const CUSTOMERS_ADD_PATH = 'CUSTOMERS_ADD_PATH';
export const CUSTOMERS_DETAIL_PATH = 'CUSTOMERS_DETAIL_PATH';
export const CUSTOMERS_EDIT_PATH = 'CUSTOMERS_EDIT_PATH';
export const CUSTOMERS_VIEW_CATALOG_PATH = 'CUSTOMERS_VIEW_CATALOG_PATH';

export const VEHICLE_MANUAL_ADD_PATH = 'VEHICLE_MANUAL_ADD_PATH';
export const VEHICLE_MANUAL_DETAIL_PATH = 'VEHICLE_MANUAL_DETAIL_PATH';
export const VEHICLE_MANUAL_EDIT_PATH = 'VEHICLE_MANUAL_EDIT_PATH';
export const VEHICLE_MASSIVE_LOAD_PATH = 'VEHICLE_MASSIVE_LOAD_PATH';
export const VEHICLE_MASSIVE_LOAD_FOR_SALES_PATH =
  'VEHICLE_MASSIVE_LOAD_FOR_SALES_PATH';
export const VEHICLE_MASSIVE_CHANGE_PATH = 'VEHICLE_MASSIVE_CHANGE_PATH';
export const VEHICLE_VIEW_CATALOG_PATH = 'VEHICLE_VIEW_CATALOG_PATH';
export const VEHICLE_HISTORIC_DETAIL_PATH = 'VEHICLE_HISTORIC_DETAIL_PATH';

export const RESERVATIONS_PATH = 'RESERVATIONS_PATH';
export const RESERVATIONS_QUOTE_PATH = 'RESERVATIONS_QUOTE_PATH';
export const RESERVATIONS_NOT_PRICE_QUOTE_PATH =
  'RESERVATIONS_NOT_PRICE_QUOTE_PATH';
export const RESERVATIONS_QUOTE_PLAN_PATH = 'RESERVATIONS_QUOTE_PLAN_PATH';
export const RESERVATIONS_QUOTE_SUMMARY_PATH =
  'RESERVATIONS_QUOTE_SUMMARY_PATH';
export const RESERVATIONS_CUSTOMER_ADD_PATH = 'RESERVATIONS_CUSTOMER_ADD_PATH';
export const RESERVATIONS_CUSTOMER_DETAIL_PATH =
  'RESERVATIONS_CUSTOMER_DETAIL_PATH';
export const RESERVATIONS_CUSTOMER_EDIT_PATH =
  'RESERVATIONS_CUSTOMER_EDIT_PATH';
export const RESERVATIONS_PAYMENT_PATH = 'RESERVATIONS_PAYMENT_PATH';
export const RESERVATIONS_DETAIL_PATH = 'RESERVATIONS_DETAIL_PATH';

export const QUOTER_PATH = 'QUOTER_PATH';
export const QUOTER_PLAN_PATH = 'QUOTER_PLAN_PATH';
export const QUOTER_SUMMARY_PATH = 'QUOTER_SUMMARY_PATH';
export const QUOTER_CUSTOMER_PATH = 'QUOTER_CUSTOMER_PATH';

export const CONTRACT_RD_PATH = 'CONTRACT_RD_PATH';
export const CONTRACT_RD_CONFIRM_PATH = 'CONTRACT_RD_CONFIRM_PATH';
export const CONTRACT_RD_CONTRACT_PATH = 'CONTRACT_RD_CONTRACT_PATH';
export const CONTRACT_RD_LEASING_PATH = 'CONTRACT_RD_LEASING_PATH';
export const CONTRACT_RD_LEASING_SIGNATURES_PATH =
  'CONTRACT_RD_LEASING_SIGNATURES_PATH';
export const CONTRACT_RD_PROMISSORY_NOTE_PATH =
  'CONTRACT_RD_PROMISSORY_NOTE_PATH';
export const CONTRACT_RD_PROMISSORY_NOTE_SIGNATURES_PATH =
  'CONTRACT_RD_PROMISSORY_NOTE_SIGNATURES_PATH';
export const CONTRACT_RD_DELIVERY_PATH = 'CONTRACT_RD_DELIVERY_PATH';
export const CONTRACT_RD_DELIVERY_VEHICLE_PARTS_PATH =
  'CONTRACT_RD_DELIVERY_VEHICLE_PARTS_PATH';
export const CONTRACT_RD_DELIVERY_INFORMATION_AND_FIRM_PATH =
  'CONTRACT_RD_DELIVERY_INFORMATION_AND_FIRM_PATH';
export const CONTRACT_RD_NOT_QUOTE_RESERVATION_PATH =
  'CONTRACT_RD_NOT_QUOTE_RESERVATION_PATH';
export const CONTRACT_RD_EXTEND_CONTRACT_PATH =
  'CONTRACT_RD_EXTEND_CONTRACT_PATH';
export const CONTRACT_RD_EXTEND_CONTRACT_SIGN_PATH =
  'CONTRACT_RD_EXTEND_CONTRACT_SIGN_PATH';
export const CONTRACT_RD_EXTEND_CONTRACT_PAYMENT_PATH =
  'CONTRACT_RD_EXTEND_CONTRACT_PAYMENT_PATH';
export const CONTRACT_RD_CLOSED_CONTRACT_PATH =
  'CONTRACT_RD_CLOSED_CONTRACT_PATH';
export const CONTRACT_RD_UNIT_INVENTORY = 'CONTRACT_RD_UNIT_INVENTORY';

export const RETURNS_REFUND_PATH = 'RETURNS_REFUND_PATH';
export const RETURNS_REFUND_VEHICLE_PARTS_PATH =
  'RETURNS_REFUND_VEHICLE_PARTS_PATH';
export const RETURNS_REFUND_INFORMATION_AND_FIRM_PATH =
  'RETURNS_REFUND_INFORMATION_AND_FIRM_PATH';
export const RETURNS_EXTRA_CHARGES_PATH = 'RETURNS_EXTRA_CHARGES_PATH';
export const RETURNS_EXTRA_CHARGES_PAYMENT_PATH =
  'RETURNS_EXTRA_CHARGES_PAYMENT_PATH';

export const LOANS_PATH = 'LOANS_PATH';
export const LOANS_ADD_PATH = 'LOANS_ADD_PATH';
export const LOANS_EDIT_PATH = 'LOANS_EDIT_PATH';
export const LOANS_DETAIL_PATH = 'LOANS_DETAIL_PATH';

export const TRANSFERS_LIST_PATH = 'TRANSFERS_LIST_PATH';
export const TRANSFERS_ADD_PATH = 'TRANSFERS_ADD_PATH';
export const TRANSFERS_EDIT_PATH = 'TRANSFERS_EDIT_PATH';
export const TRANSFERS_DETAIL_PATH = 'TRANSFERS_DETAIL_PATH';

export const CONTRACTS_LIST_PATH = 'CONTRACTS_LIST_PATH';
export const CONTRACTS_ADD_PATH = 'CONTRACTS_ADD_PATH';
export const CONTRACTS_EDIT_PATH = 'CONTRACTS_EDIT_PATH';
export const CONTRACTS_DETAIL_PATH = 'CONTRACTS_DETAIL_PATH';

export const INVOICES_LIST_PATH = 'INVOICES_LIST_PATH';
export const INVOICES_DETAIL_PATH = 'INVOICES_DETAIL_PATH';

export const PAYMENTS_LIST_PATH = 'PAYMENTS_LIST_PATH';
export const PAYMENTS_DETAIL_PATH = 'PAYMENTS_DETAIL_PATH';
export const MULTI_CONTRACT_PAYMENTS_DETAIL_PATH =
  'MULTI_CONTRACT_PAYMENTS_DETAIL_PATH';

export const REPORTS_LIST_PATH = 'REPORTS_LIST_PATH';

export const WORKSHOP_LIST_PATH = 'WORKSHOP_LIST_PATH';

export const RETURNS_LIST_PATH = 'RETURNS_LIST_PATH';

export const CATALOGS_PATH = 'CATALOGS_PATH';

export default {
  [CUSTOMERS_ADD_PATH]: new RegExp('/customers/add$'),
  [CUSTOMERS_DETAIL_PATH]: new RegExp('/customers/([a-zA-Z0-9-]*)/detail'),
  [CUSTOMERS_EDIT_PATH]: new RegExp('/customers/([a-zA-Z0-9-]*)/edit'),
  [CUSTOMERS_VIEW_CATALOG_PATH]: new RegExp('/customers/$'),
  [VEHICLE_MANUAL_ADD_PATH]: new RegExp('/vehicle/add$'),
  [VEHICLE_MANUAL_DETAIL_PATH]: new RegExp('/vehicle/([a-zA-Z0-9-]*)/detail'),
  [VEHICLE_MANUAL_EDIT_PATH]: new RegExp('/vehicle/([a-zA-Z0-9-]*)/edit'),
  [VEHICLE_MASSIVE_LOAD_PATH]: new RegExp('/vehicle/massive-load$'),
  [VEHICLE_MASSIVE_LOAD_FOR_SALES_PATH]: new RegExp(
    '/vehicle/massive-load-for-sales$'
  ),
  [VEHICLE_MASSIVE_CHANGE_PATH]: new RegExp('/vehicle/massive-change$'),
  [VEHICLE_VIEW_CATALOG_PATH]: new RegExp('/vehicle$'),
  [VEHICLE_HISTORIC_DETAIL_PATH]: new RegExp(
    '/vehicle/([a-zA-Z0-9-]*)/historic-detail'
  ),

  [RESERVATIONS_PATH]: new RegExp('/reservations$'),
  [RESERVATIONS_QUOTE_PATH]: new RegExp('/reservations/quote$'),
  [RESERVATIONS_NOT_PRICE_QUOTE_PATH]: new RegExp(
    '/reservations/contract-quote$'
  ),
  [RESERVATIONS_QUOTE_PLAN_PATH]: new RegExp('/reservations/plan$'),
  [RESERVATIONS_QUOTE_SUMMARY_PATH]: new RegExp('/reservations/summary$'),
  [RESERVATIONS_CUSTOMER_ADD_PATH]: new RegExp('/reservations/customer/add$'),
  [RESERVATIONS_CUSTOMER_DETAIL_PATH]: new RegExp(
    '/reservations/customer/([a-zA-Z0-9-]*)/detail$'
  ),
  [RESERVATIONS_CUSTOMER_EDIT_PATH]: new RegExp(
    '/reservations/customer/([a-zA-Z0-9-]*)/edit$'
  ),
  [RESERVATIONS_PAYMENT_PATH]: new RegExp('/reservations/payment$'),
  [RESERVATIONS_DETAIL_PATH]: new RegExp('/reservations/detail$'),
  [RETURNS_REFUND_PATH]: new RegExp('/returns/([a-zA-Z0-9-]*)/refund$'),
  [RETURNS_REFUND_VEHICLE_PARTS_PATH]: new RegExp(
    '/returns/([a-zA-Z0-9-]*)/refund-vehicle-parts$'
  ),
  [RETURNS_REFUND_INFORMATION_AND_FIRM_PATH]: new RegExp(
    '/returns/([a-zA-Z0-9-]*)/refund-information-and-firm$'
  ),
  [RETURNS_EXTRA_CHARGES_PATH]: new RegExp(
    '/returns/([a-zA-Z0-9-]*)/extra-charges$'
  ),
  [RETURNS_EXTRA_CHARGES_PAYMENT_PATH]: new RegExp(
    '/returns/([a-zA-Z0-9-]*)/extra-charges/payment$'
  ),
  [QUOTER_PATH]: new RegExp('/quoter/$'),
  [QUOTER_PLAN_PATH]: new RegExp('/quoter/plan$'),
  [QUOTER_SUMMARY_PATH]: new RegExp('/quoter/summary$'),
  [QUOTER_CUSTOMER_PATH]: new RegExp('/quoter/customer/add$'),
  [CONTRACT_RD_PATH]: new RegExp('/daily-rent-contracts/$'),
  [CONTRACT_RD_CONFIRM_PATH]: new RegExp('/daily-rent-contracts/confirm$'),
  [CONTRACT_RD_CONTRACT_PATH]: new RegExp('/daily-rent-contracts/contract$'),
  [CONTRACT_RD_LEASING_PATH]: new RegExp('/daily-rent-contracts/leasing$'),
  [CONTRACT_RD_PROMISSORY_NOTE_PATH]: new RegExp(
    '/daily-rent-contracts/promissory-note$'
  ),
  [CONTRACT_RD_LEASING_SIGNATURES_PATH]: new RegExp(
    '/daily-rent-contracts/leasing/signatures$'
  ),
  [CONTRACT_RD_PROMISSORY_NOTE_SIGNATURES_PATH]: new RegExp(
    '/daily-rent-contracts/promissory-note/signatures$'
  ),
  [CONTRACT_RD_DELIVERY_PATH]: new RegExp(
    '/daily-rent-contracts/([a-zA-Z0-9-]*)/delivery$'
  ),
  [CONTRACT_RD_DELIVERY_VEHICLE_PARTS_PATH]: new RegExp(
    '/daily-rent-contracts/([a-zA-Z0-9-]*)/delivery-vehicle-parts$'
  ),
  [CONTRACT_RD_DELIVERY_INFORMATION_AND_FIRM_PATH]: new RegExp(
    '/daily-rent-contracts/([a-zA-Z0-9-]*)/delivery-information-and-firm$'
  ),
  [CONTRACT_RD_NOT_QUOTE_RESERVATION_PATH]: new RegExp(
    '/daily-rent-contracts/contract-quote/$'
  ),
  [CONTRACT_RD_EXTEND_CONTRACT_PATH]: new RegExp(
    '/daily-rent-contracts/([a-zA-Z0-9-]*)/extend-contract$'
  ),
  [CONTRACT_RD_EXTEND_CONTRACT_SIGN_PATH]: new RegExp(
    '/daily-rent-contracts/([a-zA-Z0-9-]*)/extend-sign$'
  ),
  [CONTRACT_RD_EXTEND_CONTRACT_PAYMENT_PATH]: new RegExp(
    '/daily-rent-contracts/([a-zA-Z0-9-]*)/extend-payment$'
  ),
  [CONTRACT_RD_CLOSED_CONTRACT_PATH]: new RegExp(
    '/daily-rent-contracts/([a-zA-Z0-9-]*)/closed-contract$'
  ),
  [CONTRACT_RD_UNIT_INVENTORY]: new RegExp(
    '/daily-rent-contracts/([a-zA-Z0-9-]*)/unit_inventory$'
  ),

  [LOANS_PATH]: new RegExp('/loans'),
  [LOANS_ADD_PATH]: new RegExp('/loans/add$'),
  [LOANS_EDIT_PATH]: new RegExp('/loans/([a-zA-Z0-9-]*)/edit$'),
  [LOANS_DETAIL_PATH]: new RegExp('/loans/([a-zA-Z0-9-]*)/detail$'),
  [TRANSFERS_LIST_PATH]: new RegExp('/transfers$'),
  [TRANSFERS_ADD_PATH]: new RegExp('/transfers/add$'),
  [TRANSFERS_EDIT_PATH]: new RegExp('/transfers/([a-zA-Z0-9-]*)/edit$'),
  [TRANSFERS_DETAIL_PATH]: new RegExp('/transfers/([a-zA-Z0-9-]*)/detail$'),
  [CONTRACTS_LIST_PATH]: new RegExp('/contracts'),
  [CONTRACTS_ADD_PATH]: new RegExp('/contracts/add$'),
  [CONTRACTS_EDIT_PATH]: new RegExp('/contracts/([a-zA-Z0-9-]*)/edit$'),
  [CONTRACTS_DETAIL_PATH]: new RegExp('/contracts/([a-zA-Z0-9-]*)/detail$'),
  [INVOICES_LIST_PATH]: new RegExp('/invoices/$'),
  [INVOICES_DETAIL_PATH]: new RegExp('/invoices/([a-zA-Z0-9-s_]*)/detail$'),
  [PAYMENTS_LIST_PATH]: new RegExp('/payments-and-charges/$'),
  [PAYMENTS_DETAIL_PATH]: new RegExp(
    '/payments-and-charges/([a-zA-Z0-9-]*)/detail$'
  ),
  [MULTI_CONTRACT_PAYMENTS_DETAIL_PATH]: new RegExp(
    '/payments-and-charges/([a-zA-Z0-9-]*)/multi-contract-payment$'
  ),
  [REPORTS_LIST_PATH]: new RegExp('/reports$'),
  [WORKSHOP_LIST_PATH]: new RegExp('/workshop/$'),
  [RETURNS_LIST_PATH]: new RegExp('/returns/$'),

  [CATALOGS_PATH]: new RegExp('/catalogs/$'),
};
