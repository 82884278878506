import { connect } from 'react-redux';
import {
  uploadMassChange,
  downloadMassiveChangeTemplate,
  downloadMassiveChangeFailedRecords,
  closeResultMessage,
  openConfirmationMessage,
  closeConfirmationMessage,
  setMassiveChangeType,
} from 'store/vehicles/actions';
import VehicleMassChange from './VehicleMassChange';

export default connect(
  (state) => ({
    massiveChangeType: state.vehicles.massiveChangeType,
    massiveChangeRecords: state.vehicles.massiveChangeRecords,
    loader: state.vehicles.loader,
    resultMessage: state.vehicles.resultMessage,
    confirmationMessage: state.vehicles.confirmationMessage,
  }),
  {
    closeResultMessage,
    openConfirmationMessage,
    closeConfirmationMessage,
    uploadMassChange,
    downloadFailedRecords: downloadMassiveChangeFailedRecords,
    downloadTemplate: downloadMassiveChangeTemplate,
    setMassiveChangeType,
  }
)(VehicleMassChange);
