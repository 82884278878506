import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import VersionModal from './VersionModal';
import {
  fetchCatalog,
  selectBrand,
  closeResponseDialog,
} from 'store/catalogs/actions';

export default compose(
  connect(
    (state) => {
      const linesBySelectedBrand = state.catalogs?.brandSelected?.lines;

      return {
        loader: state.commons.loader,
        listBrands: state.catalogs.brand.list,
        responseDialog: state.catalogs.responseDialog,
        linesBySelectedBrand,
      };
    },
    { fetchCatalog, selectBrand, closeResponseDialog }
  )
)(VersionModal);
