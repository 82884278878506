import React, { useState, useCallback } from 'react';
import { Layout, Button, Icons } from '@casanova/casanova-common';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import { PreventChange } from 'components';
import { MASSIVE_CHANGE_TYPES_INFO } from './constants';
import StepOne from 'components/VehicleMassLoad/StepOne';
import StepTwo from 'components/VehicleMassLoad/StepTwo';
import StepHeader from './StepHeader';
import styles from './styles.module.css';
import Breadcrumb from '../../Breadcrumb';

export default function VehicleMassChange({
  history,
  uploadMassChange,
  massiveChangeType,
  massiveChangeRecords,
  downloadFailedRecords,
  loader,
  resultMessage,
  closeResultMessage,
  confirmationMessage,
  openConfirmationMessage,
  closeConfirmationMessage,
  downloadTemplate,
}) {
  const [currentStep, setCurrentStep] = useState(1);
  const [excelFile, setExcelFile] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [xmlFiles, setXmlFiles] = useState([]);
  const [initialTab] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState('upload');

  const handleExcelFile = (files) => {
    setExcelFile(files);
  };

  const handlePdfFiles = (files) => {
    setPdfFiles(files);
  };

  const handleXmlFiles = (files) => {
    setXmlFiles(files);
  };

  const handleStep = (step) => {
    if (step === 2) {
      handleMassiveChange(false);
      setCurrentStep(step);
    } else if (step === 3) {
      setConfirmationModal('upload');
      openConfirmationMessage();
    }
  };
  const handleMassiveChange = useCallback(
    (process) => {
      uploadMassChange([...excelFile, ...pdfFiles, ...xmlFiles], process);
    },
    [excelFile, pdfFiles, xmlFiles, uploadMassChange]
  );
  const handleOnCancel = () => {
    if (currentStep === 2) {
      setConfirmationModal('exit');
      openConfirmationMessage();
    } else {
      history.push('/vehicle');
    }
  };

  const handleOnConfirmCancel = () => {
    setExcelFile([]);
    setPdfFiles([]);
    setXmlFiles([]);
    setCurrentStep(1);
    closeConfirmationMessage();
  };

  const showButtons = () => {
    const hasExcelFile = !!excelFile.length;
    if (currentStep === 1 && !hasExcelFile) {
      return false;
    }
    return true;
  };

  const handleDownloadRecords = () => {
    downloadFailedRecords();
  };

  const handleConfirmModal = (process) => {
    if (process) {
      setConfirmationModal('upload');
      closeResultMessage();
      openConfirmationMessage();
    }
  };

  const handleClickDownload = useCallback(() => {
    downloadTemplate(massiveChangeType);
  }, [downloadTemplate]);

  const { open, success, errorCode } = resultMessage;

  const preventChangeStep1 = currentStep === 1 && !!excelFile.length;
  const preventChangeStep2 = currentStep === 2 && !massiveChangeRecords.process;
  const shouldPrevent = preventChangeStep1 || preventChangeStep2;

  return (
    <>
      <PreventChange preventChange={shouldPrevent} />
      <ActionModal
        open={
          success &&
          open &&
          !massiveChangeRecords.process &&
          massiveChangeRecords.failed_records.length > 0 &&
          massiveChangeRecords.success_records.length === 0
        }
        icon={<Icons.StatusWarning />}
        onClose={closeResultMessage}
        onAction={() => {
          if (massiveChangeRecords.success_records.length > 0) {
            handleConfirmModal(true);
          } else {
            closeResultMessage();
          }
        }}
        title="HAY REGISTROS CON ERROR"
        actionLabel="Ver registros"
        modalType="confirmation"
        message="Identificamos registros que contienen errores por favor corrígelos para cargarlos nuevamente."
        closeButton
      />

      <ActionModal
        open={
          success &&
          open &&
          !massiveChangeRecords.process &&
          massiveChangeRecords.failed_records.length > 0 &&
          massiveChangeRecords.success_records.length > 0
        }
        icon={<Icons.StatusWarning />}
        onClose={closeResultMessage}
        onAction={() => {
          if (massiveChangeRecords.success_records.length > 0) {
            handleConfirmModal(true);
          } else {
            closeResultMessage();
          }
        }}
        title="HAY REGISTROS CON ERROR"
        actionLabel="Continuar"
        closeLabel="Ver registros"
        message="Identificamos registros que contienen errores por favor corrígelos para cargarlos nuevamente."
        closeButton
      />

      <ActionModal
        open={success && open && massiveChangeRecords.process}
        icon={<Icons.IcoCarOk />}
        onClose={closeResultMessage}
        onAction={() => history.push('/vehicle')}
        title="¡BIEN HECHO!"
        actionLabel="Ir a Vehículos"
        modalType="confirmation"
        message={MASSIVE_CHANGE_TYPES_INFO[massiveChangeType].SUCCESS_MESSAGE}
      />

      <ActionModal
        open={!success && open}
        icon={<Icons.IcoCarError />}
        code={errorCode}
        onClose={closeResultMessage}
        onAction={() => handleMassiveChange(false)}
        title="¡UPS! ALGO PASÓ!"
        actionLabel="Reintentar"
        closeButton
        message="Hubo un error mientras procesábamos la información, por favor intenta de nuevo."
      />

      <ActionModal
        open={confirmationMessage && confirmationModal === 'back'}
        icon={<Icons.StatusWarning />}
        closeButton
        onClose={closeConfirmationMessage}
        onAction={handleOnConfirmCancel}
        title="ESTÁS A PUNTO DE SALIR"
        actionLabel="Si, continuar"
        closeLabel="Cancelar"
        message="Se perderán los archivos previamente cargados, te recomendamos guardar tus cambios antes de salir.
                ¿Estás seguro que quieres continuar?"
      />

      <ActionModal
        open={confirmationMessage && confirmationModal === 'exit'}
        icon={<Icons.StatusWarning />}
        closeButton
        onClose={closeConfirmationMessage}
        onAction={handleOnConfirmCancel}
        title="CANCELAR PROCESO"
        actionLabel="Sí"
        closeLabel="No"
        message="Al cancelar se perderán todos los archivos previamente cargados.
                ¿Estás seguro que quieres continuar?"
      />

      <ActionModal
        open={confirmationMessage && confirmationModal === 'upload'}
        icon={<Icons.StatusWarning />}
        closeButton
        onClose={closeConfirmationMessage}
        onAction={() => {
          closeConfirmationMessage();
          handleMassiveChange(true);
        }}
        title="CONFIRMACIÓN DE CARGA"
        actionLabel="Sí, continuar"
        closeLabel="Cancelar"
        message={MASSIVE_CHANGE_TYPES_INFO[massiveChangeType].successMessage(
          massiveChangeRecords.success_records.length
        )}
      />
      <Layout.Title label={<Breadcrumb />} />
      <Layout.Body showLoader={loader}>
        <div className={`${styles.vehicleMassLoad} col`}>
          <StepHeader
            massiveChangeType={massiveChangeType}
            currentStep={currentStep}
            maxSteps={MASSIVE_CHANGE_TYPES_INFO[massiveChangeType].MAX_STEPS}
            onDownload={handleClickDownload}
          />
          {currentStep === 1 && (
            <StepOne
              needFiles={
                MASSIVE_CHANGE_TYPES_INFO[massiveChangeType].NEED_FILES
              }
              excelFile={excelFile}
              pdfFiles={pdfFiles}
              xmlFiles={xmlFiles}
              handleExcelFile={handleExcelFile}
              handleXmlFiles={handleXmlFiles}
              handlePdfFiles={handlePdfFiles}
            />
          )}
          {currentStep === 2 && (
            <StepTwo
              massiveChange
              massiveChangeType={massiveChangeType}
              records={massiveChangeRecords}
              initialTab={initialTab}
              onDownloadRecords={handleDownloadRecords}
            />
          )}

          {showButtons() && (
            <div className="row mt-4">
              <div className="col-md-6 text-left">
                {currentStep >= 2 && (
                  <Button
                    onClick={() => {
                      setConfirmationModal('back');
                      openConfirmationMessage();
                    }}
                    lg
                    outline
                    id="spnBackButton"
                    className={`mr-3 button-outline-icon`}
                  >
                    <span className={styles.vehicleMassLoadBackButtom}>
                      <Icons.Atras height="1.25rem" /> Atrás
                    </span>
                  </Button>
                )}
              </div>
              <div className={`col-md-6 text-right`}>
                <Button onClick={handleOnCancel} lg outline className="mr-3">
                  Cancelar
                </Button>
                <Button
                  disabled={
                    currentStep === 2 &&
                    massiveChangeRecords.success_records.length === 0
                  }
                  onClick={() => handleStep(currentStep + 1)}
                >
                  Continuar
                </Button>
              </div>
            </div>
          )}
        </div>
      </Layout.Body>
    </>
  );
}
