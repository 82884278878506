import {
  CATALOGS_MODULE,
  VIEW_CATALOG,
} from 'utils/roles/permissions/catalogsPermissions';
import { CATALOGS_PATH } from 'utils/roles/paths';

export function catalogsAuth(permissions) {
  const allowedModules = [];

  const catalogsModule = permissions.some((permission) =>
    permission.includes(VIEW_CATALOG)
  );

  if (catalogsModule) {
    allowedModules.push(CATALOGS_MODULE);
  }

  return allowedModules;
}

export function catalogsPathsAuth(permissions) {
  const allowedPaths = [];

  const viewCatalogsPermissions = permissions.filter((permission) =>
    permission.startsWith(VIEW_CATALOG)
  );

  if (viewCatalogsPermissions.length > 0) allowedPaths.push(CATALOGS_PATH);

  return allowedPaths;
}
