import moment from 'moment';
import { transformForSelect } from 'utils/transformHelpers';
import * as _camelCase from 'lodash/camelCase';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  disabledImeiGPS,
  saveImeiSelecteds,
  selectTagGPS,
} from 'utils/catalogs';
import { ISO_DATE_FORMAT, MX_DATE_FORMAT } from 'utils/constants';
import { USER_ROLES } from '../../utils/constants';
import {
  CATALOG_FETCH_SUCCEEDED,
  CATALOG_FETCH_FAILED,
  CATALOG_FETCH_REQUESTED,
  FILTERS_FETCH_FAILED,
  FILTERS_FETCH_REQUESTED,
  FILTERS_FETCH_SUCCEEDED,
  SELECT_BRAND,
  SELECT_LINE,
  SELECT_CIRCULATION_CARD_CLASS,
  INSTALLER_FETCH_REQUESTED,
  INSTALLER_FETCH_SUCCEEDED,
  INSTALLER_FETCH_FAILED,
  SELECT_INSTALLER,
  SELECT_IMEI_GPS,
  SELECT_GPS,
  DELETE_SELECT_GPS,
  CLEAR_SELECT_GPS,
  CLEAR_IMEI_GPS_SELECTED,
  CHANGE_MODE_GPS,
  CLEAR_ONE_SELECT_GPS,
  CLEAR_SELECT_TAGS,
  SPECS_SUCCEEDED,
  SPECS_FAILED,
  SPECS_REQUESTED,
  AREAS_FETCH_REQUESTED,
  AREAS_FETCH_SUCCEEDED,
  AREAS_FETCH_FAILED,
  ROLES_FETCH_REQUESTED,
  ROLES_FETCH_SUCCEEDED,
  ROLES_FETCH_FAILED,
  MODULES_FETCH_REQUESTED,
  MODULES_FETCH_SUCCEEDED,
  MODULES_FETCH_FAILED,
  RESTART_SPECS,
  RENTAL_STATUSES_FETCH_REQUESTED,
  RENTAL_STATUSES_FETCH_SUCCEEDED,
  RENTAL_STATUSES_FETCH_FAILED,
  RENTAL_FILTER_FETCH_REQUESTED,
  RENTAL_FILTER_FETCH_SUCCEEDED,
  RENTAL_FILTER_FETCH_FAILED,
  CLOSE_RESPONSE_DIALOG,
  SET_CATALOG_FILTER,
  SET_CATALOG_PAGE,
  FETCH_CATALOG_TABLE_REQUESTED,
  FETCH_CATALOG_TABLE_SUCCEEDED,
  FETCH_CATALOG_TABLE_FAILED,
  CREATE_CATALOG_RECORD,
  CREATE_CATALOG_RECORD_SUCCEEDED,
  CREATE_CATALOG_RECORD_FAILED,
  UPDATE_CATALOG_RECORD,
  UPDATE_CATALOG_RECORD_SUCCEEDED,
  UPDATE_CATALOG_RECORD_FAILED,
  FETCH_LINE_TABLE_REQUESTED,
  FETCH_LINE_TABLE_SUCCEEDED,
  FETCH_LINE_TABLE_FAILED,
  SELECT_CATEGORY,
} from './actions-types';

const initialState = {
  responseDialog: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
    modalType: '',
  },
  provider: {
    error: false,
    errorMessage: '',
    list: [],
  },
  line: {
    error: false,
    errorMessage: '',
    list: [],
  },
  version: {
    error: false,
    errorMessage: '',
    list: [],
  },
  brand: {
    error: false,
    errorMessage: '',
    list: [],
  },
  model: {
    error: false,
    errorMessage: '',
    list: [],
  },
  color: {
    error: false,
    errorMessage: '',
    list: [],
  },
  vehicleType: {
    error: false,
    errorMessage: '',
    list: [],
  },
  paymentType: {
    error: false,
    errorMessage: '',
    list: [],
  },
  vehiclePurpose: {
    error: false,
    errorMessage: '',
    list: [],
  },
  origin: {
    error: false,
    errorMessage: '',
    list: [],
  },
  adaptationOrigin: {
    error: false,
    errorMessage: '',
    list: [],
  },
  adaptationType: {
    error: false,
    errorMessage: '',
    list: [],
  },
  conversionType: {
    error: false,
    errorMessage: '',
    list: [],
  },
  conversionOrigin: {
    error: false,
    errorMessage: '',
    list: [],
  },
  permissionType: {
    error: false,
    errorMessage: '',
    list: [],
  },
  seatType: {
    error: false,
    errorMessage: '',
    list: [],
  },
  transmissionType: {
    error: false,
    errorMessage: '',
    list: [],
  },
  fuelType: {
    error: false,
    errorMessage: '',
    list: [],
  },
  owner: {
    error: false,
    errorMessage: '',
    list: [],
  },
  circulationCardType: {
    error: false,
    errorMessage: '',
    list: [],
  },
  categorySelected: {
    id: null,
    name: null,
    versions: [],
  },
  brandSelected: {
    id: null,
    name: null,
    lines: [],
  },
  lineSelected: {
    id: null,
    name: null,
    versions: [],
  },
  circulationCardClassSelected: {
    id: null,
    name: null,
    list: [],
  },
  status: {
    error: false,
    errorMessage: '',
    list: [],
  },
  installerExtra: {
    error: false,
    errorMessage: '',
    list: [],
  },
  tagSelected: {
    id: null,
    name: null,
    uuid: null,
  },
  imeiSelecteds: [],
  selectGPS: [],
  gps: {
    error: false,
    errorMessage: '',
    list: [],
  },
  modeType: '',
  specs: null,
  areas: {
    error: false,
    errorMessage: '',
    list: [],
  },
  roles: {
    error: false,
    errorMessage: '',
    list: [],
  },
  usersStatus: {
    error: false,
    errorMessage: '',
    list: [
      {
        name: 'activo',
        value: true,
        label: 'Activo',
      },
      {
        name: 'inactivo',
        value: false,
        label: 'Inactivo',
      },
    ],
  },
  modules: {
    error: false,
    errorMessage: '',
    list: [],
  },
  rentalStatuses: {
    error: false,
    errorMessage: '',
    success: false,
    list: {
      params: {
        page: 0,
        size: 10,
      },
      searchPattern: '',
      filters: [],
      totalPages: 1,
      totalElements: 10,
      results: [],
    },
  },
  rentalFilter: {
    error: false,
    errorMessage: '',
    list: [],
  },
  brandTable: {
    list: {
      params: {
        page: 0,
        size: 10,
      },
      totalPages: 1,
      totalElements: 10,
      results: [],
    },
    loading: false,
    error: null,
  },
  lineTable: {
    list: {
      params: {
        page: 0,
        size: 10,
      },
      filters: [],
      totalPages: 1,
      totalElements: 10,
      results: [],
    },
    loading: false,
    error: null,
  },
  versionTable: {
    list: {
      params: {
        page: 0,
        size: 10,
      },
      filters: [],
      totalPages: 1,
      totalElements: 10,
      results: [],
    },
    loading: false,
    error: null,
  },
};

export function catalogReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CATALOG_PAGE:
      return {
        ...state,
        [`${action.payload.type}Table`]: {
          ...state.brandTable,
          list: {
            ...state[`${action.payload.type}Table`].list,
            params: {
              ...state[`${action.payload.type}Table`].list.params,
              page: action.payload.page,
            },
          },
          loading: false,
        },
      };
    case SET_CATALOG_FILTER:
      return {
        ...state,
        [`${action.payload.type}Table`]: {
          ...state[`${action.payload.type}Table`],
          list: {
            ...state[`${action.payload.type}Table`].list,
            params: {
              ...state[`${action.payload.type}Table`].list.params,
              ...action.payload,
            },
          },
          loading: false,
        },
      };
    case FETCH_CATALOG_TABLE_REQUESTED:
      return {
        ...state,
        [`${action.payload.type}Table`]: {
          ...state[`${action.payload.type}Table`],
          list: {
            ...state[`${action.payload.type}Table`].list,
            content: [],
          },
          loading: false,
        },
      };
    case FETCH_CATALOG_TABLE_SUCCEEDED:
      const { totalPages, totalElements } = action.payload.data;

      const transform = action.payload.data.content.map((d) => {
        const createdMoment = moment(d.createdDate, ISO_DATE_FORMAT);
        const lastModifiedMoment = moment(d.lastModifiedDate, ISO_DATE_FORMAT);
        return {
          ...d,
          createdBy: _isEmpty(d.createdBy.trim())
            ? 'Sin Información'
            : d.createdBy,
          lastModifiedBy: _isEmpty(d.lastModifiedBy.trim())
            ? 'Sin Información'
            : d.lastModifiedBy,
          createdDateFormatted: createdMoment.isValid()
            ? createdMoment.format(MX_DATE_FORMAT)
            : 'Sin Información',
          lastModifiedDateFormatted: lastModifiedMoment.isValid()
            ? lastModifiedMoment.format(MX_DATE_FORMAT)
            : 'Sin Información',
        };
      });
      return {
        ...state,
        [`${action.payload.type}Table`]: {
          ...state[`${action.payload.type}Table`],
          list: {
            ...state[`${action.payload.type}Table`].list,
            content: transform,
            totalPages,
            totalElements,
          },
          loading: false,
        },
      };
    case FETCH_CATALOG_TABLE_FAILED:
      return {
        ...state,
        [`${action.payload.type}Table`]: initialState[
          `${action.payload.type}Table`
        ],
      };
    case CLOSE_RESPONSE_DIALOG:
    case CREATE_CATALOG_RECORD:
    case UPDATE_CATALOG_RECORD:
      return {
        ...state,
        responseDialog: initialState.responseDialog,
      };
    case CREATE_CATALOG_RECORD_SUCCEEDED:
    case UPDATE_CATALOG_RECORD_SUCCEEDED:
      return {
        ...state,
        responseDialog: {
          open: true,
          success: true,
          errorCode: '',
          errorMessage: '',
        },
      };
    case CREATE_CATALOG_RECORD_FAILED:
    case UPDATE_CATALOG_RECORD_FAILED:
      return {
        ...state,
        responseDialog: {
          open: true,
          success: false,
          errorCode: action.payload.code,
          message: action.payload.message,
        },
      };
    case FETCH_LINE_TABLE_REQUESTED:
      return {
        ...state,
        lineTable: {
          ...state.lineTable,
          loading: true,
          error: null,
        },
      };
    case FETCH_LINE_TABLE_SUCCEEDED:
      return {
        ...state,
        lineTable: {
          list: action.payload.data,
          loading: false,
        },
      };
    case FETCH_LINE_TABLE_FAILED:
      return {
        ...state,
        lineTable: {
          loading: false,
          error: action.payload,
        },
      };
    case SELECT_IMEI_GPS:
      const valueGPS = action.payload.e.target.value;
      const nameGPS = action.payload.e.target.name;
      const imeiSelecteds = saveImeiSelecteds(
        state.imeiSelecteds,
        state.gps,
        nameGPS,
        valueGPS
      );
      const gpsImei = disabledImeiGPS(state.gps, imeiSelecteds);
      const tag = selectTagGPS(state.gps, valueGPS);

      return {
        ...state,
        tagSelected: {
          id: tag[0].uuid,
          name: tag[0].tag,
          uuid: tag[0].uuid,
        },
        gps: {
          ...state.gps,
          list: gpsImei,
        },
        imeiSelecteds,
      };

    case SELECT_INSTALLER:
      // eslint-disable-next-line no-shadow
      const installer = state.gpsInstallers.list.map((installer) => {
        if (installer.value === action.payload.target.value) {
          return { ...installer, disabled: true };
        }
        return { ...installer, disabled: false };
      });
      return {
        ...state,
        installerExtra: {
          ...state.installerExtra,
          list: installer,
        },
      };
    case SELECT_GPS:
      return {
        ...state,
        selectGPS: [...state.selectGPS, action.payload],
      };
    case DELETE_SELECT_GPS:
      return {
        ...state,
        selectGPS: state.selectGPS.filter(
          (gps) => gps.gps.uuid !== action.payload.gps.uuid
        ),
      };
    case CHANGE_MODE_GPS:
      return {
        ...state,
        modeType: action.payload,
      };
    case CLEAR_SELECT_GPS:
      return {
        ...state,
        selectGPS: [],
      };
    case CLEAR_ONE_SELECT_GPS:
      const imeis = state.imeiSelecteds.filter(
        (gps) => gps.selected !== action.payload
      );
      const gpsImeiDisableds = disabledImeiGPS(state.gps, imeis);
      return {
        ...state,
        gps: {
          ...state.gps,
          list: gpsImeiDisableds,
        },
        imeiSelecteds: imeis,
      };
    case CLEAR_SELECT_TAGS:
      state.gps.list.forEach((imei) => {
        // eslint-disable-next-line no-param-reassign
        imei.disabled = false;
      });

      return {
        ...state,
        gps: {
          ...state.gps,
          list: state.gps.list,
        },
      };
    case CLEAR_IMEI_GPS_SELECTED:
      return {
        ...state,
        imeiSelecteds: [],
        tagSelected: [],
      };
    case SELECT_CATEGORY:
      const category = state.vehicleCategory?.list.find(
        (categoryElement) => categoryElement.uuid === action.payload
      );
      if (category?.vehicleTypes) {
        const types = transformForSelect(category.vehicleTypes, 'uuid', 'name');
        return {
          ...state,
          categorySelected: {
            id: category.id,
            name: category.name,
            types,
          },
        };
      }
      return state;
    case SELECT_BRAND:
      const brand = state.brand.list.filter(
        (brandElement) => brandElement.uuid === action.payload.target.value
      );
      if (brand[0].lines) {
        const lines = transformForSelect(brand[0].lines, 'uuid', 'name');
        return {
          ...state,
          brandSelected: {
            id: brand[0].id,
            name: brand[0].name,
            lines,
          },
        };
      }
      return state;

    case SELECT_LINE:
      if (state.brandSelected.lines && state.brandSelected.lines.length > 0) {
        const line = state.brandSelected.lines.filter(
          (lineElement) => lineElement.uuid === action.payload.target.value
        );

        if (line[0].versions) {
          const versions = transformForSelect(line[0].versions, 'uuid', 'name');
          return {
            ...state,
            lineSelected: {
              id: line[0].id,
              name: line[0].name,
              versions,
            },
          };
        }
      }
      return state;

    case SELECT_CIRCULATION_CARD_CLASS:
      const { value } = action.payload.target;
      const types = state.circulationCardType.list.filter(
        (circulationCardType) => circulationCardType.cardClass.uuid === value
      );
      const cardTypes = transformForSelect(types, 'uuid', 'name');
      return {
        ...state,
        circulationCardClassSelected: {
          id: value,
          list: cardTypes,
        },
      };
    case CATALOG_FETCH_REQUESTED:
      return {
        ...state,
        [action.identifier]: { error: false, list: [] },
      };
    case CATALOG_FETCH_SUCCEEDED:
      return {
        ...state,
        [_camelCase(action.payload.identifier)]: {
          error: false,
          list: _get(action, 'payload.data.content', []),
        },
      };
    case CATALOG_FETCH_FAILED:
      return {
        ...state,
        [action.payload.identifier]: {
          error: true,
          errorMessage: action.payload.error.message,
        },
      };
    case INSTALLER_FETCH_REQUESTED:
      return {
        ...state,
        gps: { error: false, list: [] },
      };
    case INSTALLER_FETCH_SUCCEEDED:
      return {
        ...state,
        gps: {
          error: false,
          list: _get(action, 'payload.data.content', []),
        },
      };
    case INSTALLER_FETCH_FAILED:
      return {
        ...state,
        gps: {
          error: true,
          errorMessage: action.payload.error.message,
        },
      };
    case FILTERS_FETCH_REQUESTED:
      return {
        ...state,
        brand: {
          error: false,
          errorMessage: '',
          list: state.brand.list,
        },
        model: {
          error: false,
          errorMessage: '',
          list: state.model.list,
        },
        owner: {
          error: false,
          errorMessage: '',
          list: state.owner.list,
        },
        provider: {
          error: false,
          errorMessage: '',
          list: state.provider.list,
        },
        status: {
          error: true,
          errorMessage: '',
          list: state.status.list,
        },
      };
    case FILTERS_FETCH_SUCCEEDED:
      const { data } = action.payload.data;
      return {
        ...state,
        brand: {
          error: false,
          errorMessage: '',
          list: transformForSelect(data.brands, 'uuid', 'name'),
        },
        model: {
          error: false,
          errorMessage: '',
          list: transformForSelect(data.models, 'uuid', 'name'),
        },
        owner: {
          error: false,
          errorMessage: '',
          list: transformForSelect(data.owners, 'uuid', 'name'),
        },
        provider: {
          error: false,
          errorMessage: '',
          list: transformForSelect(data.providers, 'uuid', 'name'),
        },
        status: {
          error: true,
          errorMessage: '',
          list: transformForSelect(data.statuses, 'uuid', 'name'),
        },
      };
    case FILTERS_FETCH_FAILED:
      return {
        ...state,
        brand: {
          error: true,
          errorMessage: '',
          list: [],
        },
        model: {
          error: true,
          errorMessage: '',
          list: [],
        },
        owner: {
          error: true,
          errorMessage: '',
          list: [],
        },
        provider: {
          error: true,
          errorMessage: '',
          list: [],
        },
        status: {
          error: true,
          errorMessage: '',
          list: [],
        },
      };
    case SPECS_FAILED:
    case SPECS_REQUESTED:
    case RESTART_SPECS:
      return {
        ...state,
        specs: null,
      };
    case SPECS_SUCCEEDED:
      return {
        ...state,
        specs: action.payload.data,
      };
    case AREAS_FETCH_REQUESTED:
      return {
        ...state,
        areas: {
          error: false,
          errorMessage: '',
          list: [],
        },
      };
    case AREAS_FETCH_SUCCEEDED:
      const { content } = action.payload.data;
      return {
        ...state,
        areas: {
          error: false,
          errorMessage: '',
          list: transformForSelect(content, 'uuid', 'name'),
        },
      };
    case AREAS_FETCH_FAILED:
      return {
        ...state,
        areas: {
          error: true,
          errorMessage: action.payload,
          list: [],
        },
      };
    case ROLES_FETCH_REQUESTED:
      return {
        ...state,
        roles: {
          error: false,
          errorMessage: '',
          list: [],
        },
      };
    case ROLES_FETCH_SUCCEEDED:
      return {
        ...state,
        roles: {
          error: false,
          errorMessage: '',
          list: transformForSelect(
            action.payload.data.content,
            'uuid',
            'name'
          ).filter(({ name = '' }) =>
            action.payload.filterCustomer ? name !== USER_ROLES.CUSTOMER : true
          ),
        },
      };
    case ROLES_FETCH_FAILED:
      return {
        ...state,
        roles: {
          error: true,
          errorMessage: action.payload,
          list: [],
        },
      };
    case MODULES_FETCH_REQUESTED:
      return {
        ...state,
        modules: {
          error: false,
          errorMessage: '',
          list: [],
        },
      };
    case MODULES_FETCH_SUCCEEDED:
      return {
        ...state,
        modules: {
          error: false,
          errorMessage: '',
          list: action.payload,
        },
      };
    case MODULES_FETCH_FAILED:
      return {
        ...state,
        modules: {
          error: true,
          errorMessage: action.payload,
          list: [],
        },
      };
    case RENTAL_STATUSES_FETCH_REQUESTED:
      return {
        ...state,
        rentalStatuses: {
          error: false,
          errorMessage: '',
          success: false,
          list: {
            ...state.rentalStatuses.list,
            totalPages: 1,
            totalElements: 10,
            results: [],
          },
        },
      };
    case RENTAL_STATUSES_FETCH_SUCCEEDED:
      return {
        ...state,
        rentalStatuses: {
          error: false,
          errorMessage: '',
          success: true,
          list: {
            ...state.rentalStatuses.list,
            results: transformForSelect(action.payload.content, 'uuid', 'name'),
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements,
          },
        },
      };
    case RENTAL_STATUSES_FETCH_FAILED:
      return {
        ...state,
        rentalStatuses: {
          error: true,
          errorMessage: action.payload,
          success: false,
          list: {
            ...state.rentalStatuses.list,
            totalPages: 1,
            totalElements: 10,
            results: [],
          },
        },
      };
    case RENTAL_FILTER_FETCH_REQUESTED:
      return {
        ...state,
        rentalFilter: {
          error: false,
          errorMessage: '',
          list: [],
        },
      };
    case RENTAL_FILTER_FETCH_SUCCEEDED:
      return {
        ...state,
        rentalFilter: {
          error: false,
          errorMessage: '',
          list: action.payload,
        },
      };
    case RENTAL_FILTER_FETCH_FAILED:
      return {
        ...state,
        rentalFilter: {
          error: true,
          errorMessage: action.payload,
          list: [],
        },
      };
    default:
      return state;
  }
}
