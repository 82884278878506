import i18n from '@i18n';
import * as yup from 'yup';
import { REQUIRED_MESSAGE } from 'utils/constants';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import {
  createCatalogRecord,
  updateCatalogRecord,
} from 'store/catalogs/actions';

const label = {
  [FORM_ACTIONS.ADD]: {
    btnSubmitLabel: i18n('COMMONS__SAVE'),
    confirmation: {
      title: 'Nueva Versión',
      message: 'Se creará una nueva versión, ¿Deseas continuar?',
    },
    response: {
      successMessage: 'Se creo la nueva versión de manera exitosa.',
    },
  },
  [FORM_ACTIONS.EDIT]: {
    btnSubmitLabel: i18n('COMMONS__EDIT'),
    confirmation: {
      title: `${i18n('COMMONS__EDIT')} Versión`,
      message:
        'Se actualizará la versión de los vehículos ya asociados afectará ¿Deseas continuar?',
    },
    response: {
      successMessage: 'Se actualizó la versión de manera exitosa.',
    },
  },
};

export const initialTouched = {
  action: true,
  name: true,
  brand: true,
  line: true,
};

export const initialValues = {
  action: '',
  name: '',
  brand: '',
  line: '',
};

const schema = yup.object().shape({
  action: yup.string().required(REQUIRED_MESSAGE),
  name: yup.string().min(2).required(REQUIRED_MESSAGE),
  brand: yup.string().required(REQUIRED_MESSAGE),
  line: yup.string().required(REQUIRED_MESSAGE),
});

const formatter = (data) => {
  const { action, type, uuid, ...rest } = data;
  return {
    action,
    type,
    uuid,
    body: { ...rest },
  };
};

export const config = (action) => ({
  schema,
  initialTouched,
  initialValues,
  formatter,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  disableOnDirty: true,
  btnSubmitLabel: label[action].btnSubmitLabel,
  submitForm: (request) => {
    if (request.action === FORM_ACTIONS.ADD)
      return createCatalogRecord(request);
    return updateCatalogRecord(request);
  },
  dialogs: {
    confirmation: {
      title: label[action].confirmation.title,
      message: label[action].confirmation.message,
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: label[action].response.successMessage,
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: i18n('COMMONS__CLOSE__TEXT'),
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
  },
});
