import jwtDecode from 'jwt-decode';
import _get from 'lodash/get';

import {
  VIEW_VEHICLE_PURCHASE_SECTION,
  VIEW_VEHICLE_ADDITIONALS_SECTION,
} from 'utils/roles/permissions';

import { vehiclesAuth, vehiclesPathsAuth } from './modules/vehiclesAuth';
import { customersAuth, customersPathsAuth } from './modules/customersAuth';
import {
  reservationsModulesAuth,
  reservationsPathsAuth,
} from './modules/reservationsAuth';
import {
  dailyRentContractsAuth,
  dailyRentContractsPathsAuth,
} from './modules/dailyRentContractsAuth';
import { returnsModulesAuth, returnsPathsAuth } from './modules/returnsAuth';
import { loansAuth, loansPathsAuth } from './modules/loansAuth';
import { transfersAuth, transfersPathsAuth } from './modules/transfersAuth';
import { contractsAuth, contractsPathsAuth } from './modules/contractsAuth';
import { reportsAuth, reportsPathsAuth } from './modules/reportsAuth';
import { paymentsAuth, paymentsPathsAuth } from './modules/paymentsAuth';
import { invoicesAuth, invoicesPathsAuth } from './modules/invoicesAuth';
import { workshopAuth, workshopPathsAuth } from './modules/workshopAuth';
import { catalogsAuth, catalogsPathsAuth } from './modules/catalogsAuth';
export function transformPermission(data, roleName) {
  const { content } = data.data;

  const role = content.filter(
    (element) => element.name.toLowerCase() === roleName
  );
  const allowedPermissions = role[0].permissions.map(
    (permission) => permission.name
  );
  const purchaseSection = allowedPermissions.filter((permission) =>
    permission.includes('PURCHASE')
  );

  const additionalsSection = allowedPermissions.filter(
    (permission) =>
      permission.includes('CIRCULATION_CARD') ||
      permission.includes('CONVERSION') ||
      permission.includes('FINES') ||
      permission.includes('GPS_ORDERS') ||
      permission.includes('KEYS') ||
      permission.includes('PLATE') ||
      permission.includes('POLICY') ||
      permission.includes('ROAD_TAX') ||
      permission.includes('SINISTER') ||
      permission.includes('VERIFICATION')
  );

  if (purchaseSection.length > 0)
    allowedPermissions.push(VIEW_VEHICLE_PURCHASE_SECTION);
  if (additionalsSection.length > 0)
    allowedPermissions.push(VIEW_VEHICLE_ADDITIONALS_SECTION);

  return allowedPermissions;
}

export function transformUser(data) {
  const { headers } = data;
  const authToken = headers.get('Authorization').substring('Bearer '.length);
  const token = jwtDecode(localStorage.getItem('tkn'));
  const name = _get(token, 'nam', '');
  const lna = _get(token, 'lna', '');
  const sln = _get(token, 'sln', '');

  return {
    authToken,
    user: {
      fullName: `${name} ${lna} ${sln}`.trim(),
    },
  };
}

export function getModulePermissions(permissions) {
  const allowedModules = [
    ...vehiclesAuth(permissions),
    ...customersAuth(permissions),
    ...reservationsModulesAuth(permissions),
    ...dailyRentContractsAuth(permissions),
    ...returnsModulesAuth(permissions),
    ...loansAuth(permissions),
    ...transfersAuth(permissions),
    ...contractsAuth(permissions),
    ...reportsAuth(permissions),
    ...paymentsAuth(permissions),
    ...invoicesAuth(permissions),
    ...workshopAuth(permissions),
    ...catalogsAuth(permissions),
  ];

  return allowedModules;
}

export function getPathPermissions(permissions) {
  const allowedPaths = [
    ...vehiclesPathsAuth(permissions),
    ...customersPathsAuth(permissions),
    ...reservationsPathsAuth(permissions),
    ...dailyRentContractsPathsAuth(permissions),
    ...returnsPathsAuth(permissions),
    ...loansPathsAuth(permissions),
    ...transfersPathsAuth(permissions),
    ...contractsPathsAuth(permissions),
    ...reportsPathsAuth(permissions),
    ...paymentsPathsAuth(permissions),
    ...invoicesPathsAuth(permissions),
    ...workshopPathsAuth(permissions),
    ...catalogsPathsAuth(permissions),
  ];

  return allowedPaths;
}
