import Settings from '@casanova/casanova-common/lib/Icons/Settings';
import { withReconcileNav } from 'containers/Breadcrumb';
import { withRoleRestrictions } from 'utils/auth';
import { validateRolePermissions } from 'utils/roles';
import {
  CATALOGS_MODULE,
  VIEW_CATALOG_BRAND,
  VIEW_CATALOG_LINE,
  VIEW_CATALOG_VERSION,
} from '../roles/permissions/catalogsPermissions';
import CatalogsMain from '../../containers/Catalogs';

const show =
  validateRolePermissions(VIEW_CATALOG_BRAND) ||
  validateRolePermissions(VIEW_CATALOG_LINE) ||
  validateRolePermissions(VIEW_CATALOG_VERSION);

export const catalogs = {
  moduleName: CATALOGS_MODULE,
  route: '/catalogs',
  label: 'Catálogos',
  icon: Settings,
  main: 'all',
  show,
  children: {
    all: {
      route: '',
      label: '',
      component: withReconcileNav(
        'catalogs',
        'all'
      )(withRoleRestrictions(CatalogsMain)),
    },
  },
};
