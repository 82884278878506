import React from 'react';
import { AdditionalHeaderCamp } from 'components';
import { CellText } from 'components/ColumnsCells';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import {
  alphaMaskWithSpaces,
  onlyLettersWithSpaces,
  numericMask,
} from 'utils/masks';
import versions from '@casanova/casanova-common/lib/utils/constants/versions';
import { setCatalogParams } from '../../../store/catalogs/actions';
import { EDIT_CATALOG_BRAND } from 'utils/roles/permissions/catalogsPermissions';
import { validateRolePermissions } from 'utils/roles';

export default [
  {
    label: 'Nombre',
    field: 'name',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={alphaMaskWithSpaces(40)}
        name="name"
        searchKey="name"
      />
    ),
  },
  {
    label: 'Creado por',
    field: 'createdBy',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersWithSpaces(30)}
        name="createdBy"
        searchKey="createdBy"
      />
    ),
  },
  {
    label: 'Fecha de creación',
    field: 'createdDateFormatted',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();
      return (
        <AdditionalHeaderCamp
          name="createdDate"
          searchKey="createdDate"
          mask={numericMask(10)}
          type="DATE"
          version={versions.V2}
          datePickerProps={{
            resetDate: Boolean(values.createdDate),
            onResetDate: () => {
              setFieldValue('createdDate', '');
              dispatch(
                setCatalogParams({ type: values.type, createdDate: '' })
              );
            },
          }}
        />
      );
    },
  },
  {
    label: 'Modificado por',
    field: 'lastModifiedBy',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersWithSpaces(30)}
        name="lastModifiedBy"
        searchKey="lastModifiedBy"
      />
    ),
  },
  {
    label: 'Ultima modificación',
    field: 'lastModifiedDateFormatted',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();
      return (
        <AdditionalHeaderCamp
          name="lastModifiedDate"
          searchKey="lastModifiedDate"
          mask={numericMask(10)}
          type="DATE"
          version={versions.V2}
          datePickerProps={{
            resetDate: Boolean(values.lastModifiedDate),
            onResetDate: () => {
              setFieldValue('lastModifiedDate', '');
              dispatch(
                setCatalogParams({ type: values.type, lastModifiedDate: '' })
              );
            },
          }}
        />
      );
    },
  },
];

export const getContextualMenu = () => {
  const EDIT = {
    label: 'Editar',
    name: 'editBrand',
    disabled: false,
    options: [],
    showExpanded: false,
  };

  return {
    EDIT,
  };
};

export const contextualMenu = getContextualMenu();

export const getCatalogContextualMenu = () => {
  let menu = getContextualMenu();
  if (!validateRolePermissions(EDIT_CATALOG_BRAND)) delete menu.EDIT;
  return menu;
};
