import * as yup from 'yup';
import { setCatalogParams } from 'store/catalogs/actions';
import moment from 'moment';
import { timeZone, YYYY_MM_DD } from '@casanova/casanova-common/lib/utils/date';

const schema = yup.object().shape({
  name: yup.string(),
  createdDate: yup.string(),
  createdBy: yup.string(),
  lastModifiedDate: yup.string(),
  lastModifiedBy: yup.string(),
});

const initialValues = {
  name: '',
  createdDate: '',
  createdBy: '',
  lastModifiedDate: '',
  lastModifiedBy: '',
};

const initialTouched = {
  name: true,
  createdDate: true,
  createdBy: true,
  lastModifiedDate: true,
  lastModifiedBy: true,
};

const formatter = (values) => {
  const { createdDate, lastModifiedDate } = values;
  return {
    ...values,
    createdDate: createdDate
      ? moment(createdDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
      : '',
    lastModifiedDate: lastModifiedDate
      ? moment(lastModifiedDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
      : '',
  };
};

export const config = {
  schema,
  initialValues,
  initialTouched,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  withButtons: false,
  formatter,
  submitForm: setCatalogParams,
  dialogs: {
    confirmation: {},
    response: {},
  },
};

export default config;
