import moment from 'moment-timezone';
import _get from 'lodash/get';
import qs from 'qs';
import { formatApiUrl } from '@casanova/casanova-common/lib/utils/service';

const YYYY_MM_DD = 'YYYY-MM-DD';
const timeZone = 'America/Mexico_City';

const getObjectProp = (obj, prop) =>
  prop.split('.').reduce((r, e) => r[e], obj);

export const transformForVehicleListFilters = (data) => {
  const { statuses, substatuses } = data;
  return {
    ...data,
    statuses: statuses.map((status) => ({
      ...status,
      value: status.uuid,
      label: status.name,
    })),
    substatuses: substatuses.map((substatus) => ({
      ...substatus,
      value: substatus.uuid,
      label: substatus.name,
    })),
  };
};

export const transformForSelect = (elements, value, label, levels = {}) => {
  const {
    moreLevels = false,
    levelsValuesKey = '',
    levelsLabelsKey = '',
  } = levels;

  const selectElements = elements.map((element) => ({
    ...element,
    value: moreLevels
      ? getObjectProp(element[value], levelsValuesKey)
      : element[value],
    label: moreLevels
      ? getObjectProp(element[label], levelsLabelsKey)
      : element[label],
  }));

  return selectElements;
};

export const transformForSaveVehicle = (vehicleForm) => ({
  ...transformForVehicleData(vehicleForm),
  hasAdaptation: vehicleForm.hasAdaptation,
  hasConversion: vehicleForm.hasConversion,
  adaptation: vehicleForm.hasAdaptation
    ? transformForAdaptation(vehicleForm)
    : null,
  purchase: transformForPurchase(vehicleForm),
  conversion: vehicleForm.hasConversion
    ? transformForConversion(vehicleForm)
    : null,
  permission: vehicleForm.permissionType
    ? transformForPermission(vehicleForm)
    : null,
});

export const transformForVehicleData = (vehicle) => {
  const {
    category,
    color,
    cylinder,
    engineNumber,
    fuelType,
    gpsNumber,
    keys,
    loadCapacity,
    mileage,
    model,
    origin,
    passengers,
    serialNumber,
    vehicularKey,
    version,
    vehicleType: type,
    personalUse,
    personalUseRemarks,
    personalUseRequester,
    seatType,
    transmissionType,
    doors,
    businessArea,
  } = vehicle;

  return {
    category,
    color,
    cylinder,
    engineNumber,
    fuelType,
    gpsNumber,
    keys,
    loadCapacity,
    mileage,
    model,
    origin,
    passengers,
    serialNumber,
    type,
    vehicularKey,
    version,
    personalUse,
    personalUseRemarks,
    personalUseRequester,
    seatType,
    transmissionType,
    doors,
    businessArea,
  };
};

export const transformSpecs = (data) => {
  if (data) {
    const {
      cylinder,
      doors,
      loadCapacity,
      passengers,
      seatType,
      transmissionType,
      fuelType,
    } = data;
    return {
      cylinder,
      doors,
      loadCapacity,
      passengers,
      fuelType: fuelType.uuid,
      seatType: seatType.uuid,
      transmissionType: transmissionType.uuid,
    };
  }

  return null;
};

export const transformForAdaptation = (vehicle) => ({
  origin: vehicle.originAdaptation,
  type: vehicle.adaptationType,
  price: vehicle.adaptationCost.replace(/[^0-9.]+/g, ''),
  invoiceNumber: vehicle.adaptationInvoiceNumber,
  adaptationInvoicePDFName: `${vehicle.serialNumber}_${vehicle.adaptationInvoiceNumber}_REC_ADP.pdf`,
  adaptationInvoiceXMLName: `${vehicle.serialNumber}_${vehicle.adaptationInvoiceNumber}_REC_ADP.xml`,
});

export const transformForPurchase = (vehicle) => {
  const {
    invoiceNumber,
    owner,
    provider,
    buyDate,
    invoicePrice,
    purchaseOrder,
    paymentType,
    serialNumber,
    description,
    personalUse,
    personalUseRequester,
    personalUseRemarks,
  } = vehicle;
  return {
    owner,
    provider,
    date: moment(buyDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD),
    description,
    paymentType,
    price: invoicePrice.replace(/[^0-9.]+/g, ''),
    purchaseOrder,
    invoiceNumber,
    personalUse,
    personalUseRequester,
    personalUseRemarks,
    invoicePDFName: `${serialNumber}_${invoiceNumber}_REC.pdf`,
    invoiceName: `${serialNumber}_${invoiceNumber}_REC.xml`,
    invoiceCopyName: `${serialNumber}_${invoiceNumber}_REC_SIN_VALOR.pdf`,
  };
};

export const transformForConversion = (vehicle) => ({
  origin: vehicle.originConversion,
  type: vehicle.conversionType,
  price: vehicle.conversionCost.replace(/[^0-9.]+/g, ''),
  invoiceNumber: vehicle.conversionInvoiceNumber,
  conversionInvoicePDFName: vehicle.serialNumber
    ? `${vehicle.serialNumber}_${vehicle.conversionInvoiceNumber}_REC_CON.pdf`
    : null,
  conversionInvoiceXMLName: vehicle.serialNumber
    ? `${vehicle.serialNumber}_${vehicle.conversionInvoiceNumber}_REC_CON.xml`
    : null,
});

export const transformForPermission = (vehicle) => ({
  permissionDate: moment(vehicle.processingDate, YYYY_MM_DD)
    .tz(timeZone)
    .format(YYYY_MM_DD),
  permissionType: vehicle.permissionType,
  permissionInvoicePDFName: vehicle.serialNumber
    ? `${vehicle.serialNumber}_${vehicle.conversionInvoiceNumber}_REC_PRM.pdf`
    : null,
});
export const transform2FormData = (form, json) => {
  const keys = Object.keys(json);
  keys.forEach((key) => {
    form.append(key, json[key]);
  });

  return form;
};

export const transformForSaveFiles = (vehicleForm) => {
  const formData = new FormData();

  formData.append(
    'filesBill',
    vehicleForm.invoicePDF,
    vehicleForm.invoicePDF.name
  );
  formData.append('filesBill', vehicleForm.invoice, vehicleForm.invoice.name);

  if (vehicleForm.hasAdaptation) {
    formData.append(
      'filesAdaptation',
      vehicleForm.adaptationInvoicePDF,
      vehicleForm.adaptationInvoicePDF.name
    );
    formData.append(
      'filesAdaptation',
      vehicleForm.adaptationinvoiceXML,
      vehicleForm.adaptationinvoiceXML.name
    );
  }

  return formData;
};

export const transformForGetDetail = (vehicle) => {
  try {
    const mileage = _get(vehicle, 'mileage', '');
    const permissionDatee = _get(vehicle, 'permission.permissionDate', null);
    const status = _get(vehicle, 'vehicleStatus.name', '');
    const purchase = _get(vehicle, 'purchase', {});
    const owner = _get(purchase, 'owner', {});
    const provider = _get(purchase, 'provider', {});

    return {
      modelsDetail: transformForSelect([vehicle.model], 'uuid', 'name'),
      adaptationActions: vehicle.adaptation
        ? {
            inserted: vehicle.adaptation.inserted,
            updated: vehicle.adaptation.updated,
          }
        : null,
      inserted: vehicle.inserted,
      updated: vehicle.updated,
      purchaseUuid: purchase.uuid,
      purchaseActions: purchase
        ? {
            inserted: purchase.inserted,
            updated: purchase.updated,
          }
        : null,
      vehicleUuid: vehicle.uuid,
      adaptationUuid: vehicle.adaptation ? vehicle.adaptation.uuid : '',
      status: status.toUpperCase(),
      brand: vehicle.brand.uuid,
      line: vehicle.line.uuid,
      version: vehicle.version.uuid,
      model: vehicle.model.uuid,
      modelKey: vehicle.model.uuid,
      serialNumber: vehicle.serialNumber.toString(),
      engineNumber: vehicle.engineNumber.toString(),
      vehicularKey: vehicle.vehicularKey.toString(),
      color: vehicle.color.uuid,
      origin: vehicle.origin.uuid,
      cylinder: vehicle.cylinder,
      fuelType: _get(vehicle, 'fuelType.uuid', ''),
      category: _get(vehicle, 'category.uuid', ''),
      passengers: vehicle.passengers,
      loadCapacity: _get(vehicle, 'loadCapacity', ''),
      vehicleType: _get(vehicle, 'type.uuid', ''),
      mileage: mileage.toString(),
      keys: vehicle.keys.toString(),
      personalUse: _get(vehicle, 'purchase.personalUse', ''),
      personalUseRemarks: _get(vehicle, 'purchase.personalUseRemarks', ''),
      personalUseRequester: _get(vehicle, 'purchase.personalUseRequester', ''),
      doors: vehicle.doors,
      seatType: _get(vehicle, 'seatType.uuid', ''),
      transmissionType: _get(vehicle, 'transmissionType.uuid', ''),
      description: _get(vehicle, 'purchase.description', ''),
      // gpsNumber: vehicle.gpsNumber.toString(),
      hasAdaptation: !!vehicle.adaptation,
      hasConversion: !!vehicle.conversion,
      owner: owner.uuid,
      provider: provider.uuid,
      buyDate: moment(purchase.date, YYYY_MM_DD)
        .tz(timeZone)
        .format(YYYY_MM_DD),
      initialBuyDate: moment(purchase.date, YYYY_MM_DD)
        .tz(timeZone)
        .format(YYYY_MM_DD),
      paymentType: _get(vehicle, 'paymentType.uuid', ''),
      invoicePrice: _get(purchase, 'price', '').toString(),
      purchaseOrder: _get(purchase, 'purchaseOrder', ''),
      initialInvoiceNumber: _get(purchase, 'invoiceNumber', '').toString(),
      invoiceNumber: _get(purchase, 'invoiceNumber', '').toString(),
      pdfUrl: purchase.pdf ? purchase.pdf.presignedUrl : '',
      xmlUrl: purchase.xml ? purchase.xml.presignedUrl : '',
      invoicePDF: purchase.pdf ? purchase.pdf.originalName : '',
      invoice: purchase.xml ? purchase.xml.originalName : '',
      invoiceCopy: _get(vehicle, 'purchase.pdfNoValue.originalName', ''),
      invoiceCopyUrl: _get(vehicle, 'purchase.pdfNoValue.presignedUrl', ''),

      originAdaptation: vehicle.adaptation
        ? vehicle.adaptation.origin.uuid
        : null,
      adaptationType: vehicle.adaptation ? vehicle.adaptation.type.uuid : null,
      initialAdaptationCost: vehicle.adaptation
        ? vehicle.adaptation.price.toString()
        : null,
      initialAdaptationInvoiceNumber: vehicle.adaptation
        ? vehicle.adaptation?.invoiceNumber?.toString()
        : null,
      adaptationCost: vehicle.adaptation
        ? vehicle.adaptation.price.toString()
        : null,
      adaptationInvoiceNumber: vehicle.adaptation
        ? vehicle.adaptation?.invoiceNumber?.toString()
        : null,
      adaptationPDFUrl: vehicle.adaptation
        ? vehicle.adaptation.pdf
          ? vehicle.adaptation.pdf.presignedUrl
          : ''
        : null,
      adaptationXMLUrl: vehicle.adaptation
        ? vehicle.adaptation.xml
          ? vehicle.adaptation.xml.presignedUrl
          : ''
        : null,
      adaptationInvoicePDF: vehicle.adaptation
        ? vehicle.adaptation.pdf
          ? vehicle.adaptation.pdf.originalName
          : ''
        : null,
      adaptationInvoiceXML: vehicle.adaptation
        ? vehicle.adaptation.xml
          ? vehicle.adaptation.xml.originalName
          : ''
        : null,

      originConversion: _get(vehicle, 'conversion.origin.uuid', null),
      conversionType: _get(vehicle, 'conversion.type.uuid', null),
      initialConversionCost: _get(vehicle, 'conversion.price', '').toString(),
      initialConversionInvoiceNumber: _get(
        vehicle,
        'conversion.invoiceNumber',
        ''
      ).toString(),
      conversionCost: _get(vehicle, 'conversion.price', '').toString(),
      conversionInvoiceNumber: _get(
        vehicle,
        'conversion.invoiceNumber',
        ''
      ).toString(),
      conversionPDFUrl: _get(vehicle, 'conversion.pdf', null),
      conversionXMLUrl: _get(vehicle, 'conversion.xml', null),
      conversionInvoicePDF: _get(vehicle, 'conversion.pdf', null),
      conversionInvoiceXML: _get(vehicle, 'conversion.xml', null),

      permissionType: _get(vehicle, 'permission.permissionType.uuid', null),
      processingDate: permissionDatee
        ? moment(permissionDatee, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
        : null,
      permissionPDF: _get(vehicle, 'permission.pdf', null),
    };
  } catch (error) {
    console.error('Error transforming: ', error);
  }
};

export const transformForVehicleFilters = (filters, search) => {
  let filtersParams = {
    pattern: search,
  };
  filters.forEach((filter) => {
    filtersParams = {
      ...filtersParams,
      [filter.name]: filter.value,
    };
  });
  return filtersParams;
};

export const transformForAllParamsUrl = (params, vehicleFilters, search) => {
  const vehicleParams = transformForVehicleFilters(vehicleFilters, search);
  return formatApiUrl(
    `management/export?${qs.stringify(params)}&${qs.stringify(vehicleParams)}`
  );
};
export const getUuidFromUrl = (url) => {
  if (url) {
    const parts = url.split('/');
    return parts[2];
  }
  return null;
};

export const transformForDetail = (uuid) =>
  formatApiUrl(`management/export/${uuid}`);

export const transformIdOfList = (list, id) => {
  const transformedList = list.map((element = {}, idx) => {
    element.id = id ? element[id] : idx;
    return element;
  });
  return transformedList;
};

export const transformForRadioGroup = (elements, value, label) =>
  elements.map((element) => ({
    ...element,
    value: Boolean(element[value]),
    label: element[label],
  }));

export const replaceElement = (
  elements = [],
  elementToReplace,
  elementIdToReplace,
  valueToCompare
) =>
  elements.map((element) => {
    const sameElement = element[elementIdToReplace] === valueToCompare;
    return sameElement ? elementToReplace : element;
  });

export const transformForOptionBar = ({
  elements,
  value,
  label,
  sub = '',
  check = '',
  verifyCheck = null,
}) =>
  elements.map((element) => ({
    ...element,
    value: element[value],
    label: element[label],
    sub: sub ? element[sub] : '',
    check: verifyCheck ? verifyCheck(element) : element[check] || '',
  }));

export const transformDropdownElements = ({
  elements = [],
  name = 'name',
  label = 'label',
  disabled = 'disabled',
  options,
}) => {
  const elementsToTransform = Array.isArray(elements)
    ? elements
    : Object.values(elements);
  return elementsToTransform.map((element) => ({
    ...element,
    name: element[name],
    label: element[label],
    disabled: element[disabled],
    options: element[options],
  }));
};
