export const VEHICLES_FETCH_ALL = 'VEHICLES_FETCH_ALL';
export const VEHICLES_FETCH_REQUESTED = 'VEHICLES_FETCH_REQUESTED';
export const VEHICLES_FETCH_SUCCEEDED = 'VEHICLES_FETCH_SUCCEEDED';
export const VEHICLES_FETCH_FAILED = 'VEHICLES_FETCH_FAILED';

export const VEHICLES_FETCH_FILTER = 'VEHICLES_FETCH_FILTER';
export const VEHICLES_FETCH_FILTER_SUCCEEDED =
  'VEHICLES_FETCH_FILTER_SUCCEEDED';
export const VEHICLES_FETCH_FILTER_FAILED = 'VEHICLES_FETCH_FILTER_FAILED';

export const VEHICLES_UPDATE_LIST = 'vehicles/VEHICLES_UPDATE_LIST';

export const FETCH_VEHICLE_INFO_REQUESTED = 'FETCH_VEHICLE_INFO_REQUESTED';
export const FETCH_VEHICLE_INFO_SUCCEEDED = 'FETCH_VEHICLE_INFO_SUCCEEDED';
export const FETCH_VEHICLE_INFO_FAILED = 'FETCH_VEHICLE_INFO_FAILED';

export const SET_PAGE_TABLE_VEHICLES = 'SET_PAGE_TABLE_VEHICLES';
export const SET_VEHICLES_SEARCH = 'SET_VEHICLES_SEARCH';

export const CLEAN_VEHICLE_DATA = 'CLEAN_VEHICLE_DATA';

export const SAVE_VEHICLE_REQUESTED = 'SAVE_VEHICLE_REQUESTED';
export const SAVE_VEHICLE_SUCCEEDED = 'SAVE_VEHICLE_SUCCEEDED';
export const SAVE_VEHICLE_FAILED = 'SAVE_VEHICLE_FAILED';

export const EDIT_VEHICLE_REQUESTED = 'EDIT_VEHICLE_REQUESTED';
export const EDIT_VEHICLE_SUCCEEDED = 'EDIT_VEHICLE_SUCCEEDED';
export const EDIT_VEHICLE_FAILED = 'EDIT_VEHICLE_FAILED';

export const SHOW_CONFIRMATION_MESSAGE = 'SHOW_CONFIRMATION_MESSAGE';
export const CLOSE_CONFIRMATION_MESSAGE = 'CLOSE_CONFIRMATION_MESSAGE';

export const CLOSE_RESULT_MESSAGE = 'CLOSE_RESULT_MESSAGE';

export const STORE_VEHICLE_DATA = 'STORE_VEHICLE_DATA';

export const MODIFY_FILTERS = 'MODIFY_FILTERS';

export const SET_MASSIVE_LOAD_TYPE = 'vehicles/SET_MASSIVE_LOAD_TYPE';
export const SET_MASSIVE_CHANGE_TYPE = 'vehicles/SET_MASSIVE_CHANGE_TYPE';

export const UPLOAD_VEHICLES = 'UPLOAD_VEHICLES';
export const UPLOAD_VEHICLES_SUCCEEDED = 'UPLOAD_VEHICLES_SUCCEEDED';
export const UPLOAD_VEHICLES_FAILED = 'UPLOAD_VEHICLES_FAILED';
export const DOWNLOAD_FAILED_RECORDS = 'DOWNLOAD_FAILED_RECORDS';
export const DOWNLOAD_MASSIVE_CHANGE_FAILED_RECORDS =
  'DOWNLOAD_MASSIVE_CHANGE_FAILED_RECORDS';

export const UPLOAD_MASSIVE_CHANGE = 'UPLOAD_MASSIVE_CHANGE';
export const UPLOAD_MASSIVE_CHANGE_SUCCEEDED =
  'UPLOAD_MASSIVE_CHANGE_SUCCEEDED';
export const UPLOAD_MASSIVE_CHANGE_FAILED = 'UPLOAD_MASSIVE_CHANGE_FAILED';

export const EXPORT_CATALOG_RECORDS = 'EXPORT_CATALOG_RECORDS';
export const EXPORT_CATALOG_RECORDS_SUCCEEDED =
  'EXPORT_CATALOG_RECORDS_SUCCEEDED';
export const EXPORT_CATALOG_RECORDS_FAILED = 'EXPORT_CATALOG_RECORDS_FAILED';
export const EXPORT_VEHICLE_DETAIL = 'EXPORT_VEHICLE_DETAIL';

export const DOWNLOAD_TEMPLATE_MASSIVE_LOAD = 'DOWNLOAD_TEMPLATE_MASSIVE_LOAD';
export const DOWNLOAD_TEMPLATE_MASSIVE_CHANGE =
  'DOWNLOAD_TEMPLATE_MASSIVE_CHANGE';

export const GET_ADDITIONAL = 'GET_ADDITIONAL';
export const GET_ADDITIONAL_SUCCEED = 'GET_ADDITIONAL_SUCCEED';
export const SAVE_ADDITIONAL = 'SAVE_ADDITIONAL';
export const SAVE_ADDITIONAL_SUCCEED = 'SAVE_ADDITIONAL_SUCCEED';
export const SAVE_ADDITIONAL_FAILED = 'SAVE_ADDITIONAL_FAILED';
export const DOWNLOAD_ADDITIONAL_FILE = 'DOWNLOAD_ADDITIONAL_FILE';
export const ADD_NEW_ADDITIONAL = 'ADD_NEW_ADDITIONAL';
export const CANCEL_NEW_ADDITIONAL = 'CANCEL_NEW_ADDITIONAL';
export const CLOSE_ADDITIONAL_RESULT = 'CLOSE_ADDITIONAL_RESULT';
export const CLEAN_ADDITIONAL = 'vehicles/CLEAN_ADDITIONAL';
export const RESET = 'vehicles/RESET';

export const GET_TYPES = 'vehicles/GET_TYPES';
export const GET_TYPES_SUCCEEDED = 'vehicles/GET_TYPES_SUCCEEDED';
export const GET_TYPES_FAILED = 'vehicles/GET_TYPES_FAILED';

export const VEHICLE_STATUS_REQUESTED = 'vehicles/VEHICLE_STATUS_REQUESTED';
export const VEHICLE_STATUS_SUCCEEDED = 'vehicles/VEHICLE_STATUS_SUCCEEDED';
export const VEHICLE_STATUS_FAILED = 'vehicles/VEHICLE_STATUS_FAILED';
export const SET_PAGE_VEHICLE_STATUS = 'vehicles/SET_PAGE_VEHICLE_STATUS';

export const VEHICLE_CONTRACTS_REQUESTED =
  'vehicles/VEHICLE_CONTRACTS_REQUESTED';
export const VEHICLE_CONTRACTS_SUCCEEDED =
  'vehicles/VEHICLE_CONTRACTS_SUCCEEDED';
export const VEHICLE_CONTRACTS_FAILED = 'vehicles/VEHICLE_CONTRACTS_FAILED';
export const SET_PAGE_VEHICLE_CONTRACTS = 'vehicles/SET_PAGE_VEHICLE_CONTRACTS';

export const VEHICLE_LOANS_REQUESTED = 'vehicles/VEHICLE_LOANS_REQUESTED';
export const VEHICLE_LOANS_SUCCEEDED = 'vehicles/VEHICLE_LOANS_SUCCEEDED';
export const VEHICLE_LOANS_FAILED = 'vehicles/VEHICLE_LOANS_FAILED';
export const SET_PAGE_VEHICLE_LOANS = 'vehicles/SET_PAGE_VEHICLE_LOANS';

export const VEHICLE_TRANSFERS_REQUESTED =
  'vehicles/VEHICLE_TRANSFERS_REQUESTED';
export const VEHICLE_TRANSFERS_SUCCEEDED =
  'vehicles/VEHICLE_TRANSFERS_SUCCEEDED';
export const VEHICLE_TRANSFERS_FAILED = 'vehicles/VEHICLE_TRANSFERS_FAILED';
export const SET_PAGE_VEHICLE_TRANSFERS = 'vehicles/SET_PAGE_VEHICLE_TRANSFERS';

export const VEHICLE_VIRTUAL_CONTRACTS_REQUESTED =
  'vehicles/VEHICLE_VIRTUAL_CONTRACTS_REQUESTED';
export const VEHICLE_VIRTUAL_CONTRACT_SUCCEEDED =
  'vehicles/VEHICLE_VIRTUAL_CONTRACT_SUCCEEDED';
export const VEHICLE_VIRTUAL_CONTRACT_FAILED =
  'vehicles/VEHICLE_VIRTUAL_CONTRACT_FAILED';
export const SET_PAGE_VEHICLE_VIRTUAL_CONTRACT =
  'vehicles/SET_PAGE_VEHICLE_VIRTUAL_CONTRACT';

export const FETCH_HISTORIC_DETAILS_REQUESTED =
  'vehicles/FETCH_HISTORIC_DETAILS_REQUESTED';
export const FETCH_HISTORIC_DETAILS_SUCCEEDED =
  'vehicles/FETCH_HISTORIC_DETAILS_SUCCEEDED';
export const FETCH_HISTORIC_DETAILS_FAILED =
  'vehicles/FETCH_HISTORIC_DETAILS_FAILED';

export const EDIT_VEHICLE_DETAILS_REQUESTED =
  'vehicles/EDIT_VEHICLE_DETAILS_REQUESTED';
export const EDIT_VEHICLE_DETAILS_SUCCEEDED =
  'vehicles/EDIT_VEHICLE_DETAILS_SUCCEEDED';
export const EDIT_VEHICLE_DETAILS_FAILED =
  'vehicles/EDIT_VEHICLE_DETAILS_FAILED';

export const CLOSE_RESPONSE_DIALOG = 'vehicles/CLOSE_RESPONSE_DIALOG';
export const CLOSE_RESPONSE_DIALOG_VC = 'vehicles/CLOSE_RESPONSE_DIALOG_VC';

export const FETCH_VEHICLE_DETAILS_REQUESTED =
  'vehicles/FETCH_VEHICLE_DETAILS_REQUESTED';
export const FETCH_VEHICLE_DETAILS_SUCCEEDED =
  'vehicles/FETCH_VEHICLE_DETAILS_SUCCEEDED';
export const FETCH_VEHICLE_DETAILS_FAILED =
  'vehicles/FETCH_VEHICLE_DETAILS_FAILED';
export const CLEAN_VEHICLE_DETAILS = 'vehicles/CLEAN_VEHICLE_DETAILS';

export const MARK_VEHICLE_FOR_SALE_REQUESTED =
  'vehicles/MARK_VEHICLE_FOR_SALE_REQUESTED';
export const MARK_VEHICLE_FOR_SALE_SUCCEEDED =
  'vehicles/MARK_VEHICLE_FOR_SALE_SUCCEEDED';
export const MARK_VEHICLE_FOR_SALE_FAILED =
  'vehicles/MARK_VEHICLE_FOR_SALE_FAILED';

export const SEARCH_VEHICLES = 'vehicles/SEARCH_VEHICLES';
export const SEARCH_VEHICLES_SUCCEEDED = 'vehicles/SEARCH_VEHICLES_SUCCEEDED';
export const SEARCH_VEHICLES_FAILED = 'vehicles/SEARCH_VEHICLES_FAILED';
export const CLEAN_SEARCH_VEHICLES = 'vehicles/CLEAN_SEARCH_VEHICLES';
