const GENERAL = {
  CONFIRMATION_MESSAGE:
    'Una vez cargados lo cambios tendrás que editarlos manualmente ¿Estás seguro que quieres continuar?',
  successMessage: (
    recordsLength
  ) => `Se cambiaron ${recordsLength} vehículos de forma exitosa, si deseas realizar algún cambio tendrás 
  que buscarlo y editarlo manualmente.`,
  MAX_STEPS: 3,
  STEPS: {
    1: {
      title: 'Carga de archivos',
      subtitle:
        'Adjunta los archivos Excel con la información de los vehículos.',
    },
    2: {
      title: 'Valida los datos extraídos',
      subtitle: 'Asegúrate que la información de los vehículos sea correcta.',
    },
    3: {
      title: 'Valida los datos extraídos',
      subtitle: 'Asegúrate que la información de los vehículos sea correcta.',
    },
  },
  NEED_FILES: false,
};

export const MASSIVE_CHANGE_TYPES_INFO = {
  GENERAL,
};
