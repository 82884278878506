import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  MaskedInput as Input,
  Loader,
  Section,
  withForm,
  Select,
} from '@casanova/casanova-common';
import { Blank } from 'components';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import i18n from '@i18n';
import _noop from 'lodash/noop';
import { config as finalConfig } from './config';
import { useFormikContext } from 'formik';

const { ADD, EDIT } = FORM_ACTIONS;

const DetailVersion = (props) => {
  const {
    selectedVersion = {},
    selectBrand,
    listBrands,
    linesBySelectedBrand,
    fetchCatalog,
    action = ADD,
    type,
  } = props;

  const { setFieldValue, setValues, values } = useFormikContext();

  const title = `${action === EDIT ? i18n('COMMONS__EDIT') : 'Nueva'} Versión`;

  const handleSelectBrand = useCallback(
    (e) => {
      setFieldValue('line', undefined);
      selectBrand(e);
    },
    [selectBrand, setFieldValue]
  );

  useEffect(() => {
    setFieldValue('action', action);
    setFieldValue('type', type);
    if (action === EDIT) {
      selectBrand({ target: { value: selectedVersion.brandUuid } });
      setFieldValue('name', selectedVersion.name);
      setFieldValue('uuid', selectedVersion.uuid);
      setFieldValue('brand', selectedVersion.brandUuid);
      setFieldValue('line', selectedVersion.lineUuid);
    }
  }, [action, selectedVersion, setFieldValue]);

  useEffect(() => {
    ['brand', 'line'].forEach(fetchCatalog);
  }, [fetchCatalog]);

  return (
    <>
      <Section title={title}>
        <div className="row">
          <div className="col-4">
            <Input
              label={`${i18n('COMMONS__VERSION')}*`}
              placeholder={`${i18n('COMMONS__VERSION')}*`}
              name="name"
            />
          </div>
          <div className="col-4">
            <Select
              label={`${i18n('COMMONS__BRAND')}*`}
              placeholder={`${i18n('COMMONS__BRAND')}*`}
              name="brand"
              onChange={handleSelectBrand}
              options={listBrands}
            />
          </div>
          <div className="col-4">
            <Select
              label={`${i18n('COMMONS__LINE')}*`}
              placeholder={`${i18n('COMMONS__LINE')}*`}
              name="line"
              options={linesBySelectedBrand}
            />
          </div>
        </div>
      </Section>
    </>
  );
};

const BrandModal = (props) => {
  const {
    show = false,
    onClose = _noop,
    onSuccess,
    loader = false,
    selectedVersion = {},
    closeResponseDialog,
    responseDialog,
    action,
  } = props;

  const [hide, setHide] = useState(false);
  const [shouldDisabledSubmit, setShouldDisabledSubmit] = useState(false);

  const handleBeforeSubmit = useCallback(() => {
    setHide(true);
  }, []);

  const handleSuccess = useCallback(() => {
    setHide(false);
    onSuccess();
  }, []);

  const handleClose = useCallback(() => {
    setHide(false);
    onClose();
  }, []);

  const config = useMemo(() => {
    return {
      ...finalConfig(action),
      onBeforeSubmit: handleBeforeSubmit,
      onSuccess: handleSuccess,
      onClose: handleClose,
    };
  }, [action, handleBeforeSubmit, handleSuccess]);

  const DetailVersionWithForm = withForm({ config })(DetailVersion)({
    ...props,
    selectedVersion,
    layout: false,
    left: <Blank />,
    onCancel: handleClose,
    closeResponseDialog: () => {
      if (responseDialog.open) {
        handleSuccess();
      }
      closeResponseDialog();
    },
    disabledSubmit: shouldDisabledSubmit,
    shouldDisabledSubmit,
    setShouldDisabledSubmit,
  });

  return (
    <>
      {/* <Loader show={hide && !loader && show} /> */}
      <Modal
        open={!loader && show}
        header={null}
        footer={null}
        onClose={onClose}
        topContainerClassName={`${hide ? 'd-none' : ''}`}
        bigModal
        closeButton
      >
        {DetailVersionWithForm}
      </Modal>
    </>
  );
};

export default BrandModal;
