import {
  CATALOG_FETCH_SUCCEEDED,
  CATALOG_FETCH_FAILED,
  CATALOG_FETCH_REQUESTED,
  FILTERS_FETCH_FAILED,
  FILTERS_FETCH_REQUESTED,
  FILTERS_FETCH_SUCCEEDED,
  SELECT_BRAND,
  SELECT_LINE,
  SELECT_CIRCULATION_CARD_CLASS,
  INSTALLER_FETCH_REQUESTED,
  INSTALLER_FETCH_SUCCEEDED,
  INSTALLER_FETCH_FAILED,
  SELECT_INSTALLER,
  SELECT_IMEI_GPS,
  SELECT_GPS,
  DELETE_SELECT_GPS,
  CLEAR_SELECT_GPS,
  CLEAR_IMEI_GPS_SELECTED,
  CHANGE_MODE_GPS,
  CLEAR_ONE_SELECT_GPS,
  CLEAR_SELECT_TAGS,
  SPECS_SUCCEEDED,
  SPECS_FAILED,
  SPECS_REQUESTED,
  AREAS_FETCH_REQUESTED,
  AREAS_FETCH_SUCCEEDED,
  AREAS_FETCH_FAILED,
  MODULES_FETCH_REQUESTED,
  MODULES_FETCH_SUCCEEDED,
  MODULES_FETCH_FAILED,
  ROLES_FETCH_REQUESTED,
  ROLES_FETCH_SUCCEEDED,
  ROLES_FETCH_FAILED,
  RESTART_SPECS,
  RENTAL_STATUSES_FETCH_REQUESTED,
  RENTAL_STATUSES_FETCH_SUCCEEDED,
  RENTAL_STATUSES_FETCH_FAILED,
  RENTAL_FILTER_FETCH_REQUESTED,
  RENTAL_FILTER_FETCH_SUCCEEDED,
  RENTAL_FILTER_FETCH_FAILED,
  CLOSE_RESPONSE_DIALOG,
  FETCH_CATALOG_TABLE_REQUESTED,
  FETCH_CATALOG_TABLE_SUCCEEDED,
  FETCH_CATALOG_TABLE_FAILED,
  CREATE_CATALOG_RECORD,
  CREATE_CATALOG_RECORD_SUCCEEDED,
  CREATE_CATALOG_RECORD_FAILED,
  UPDATE_CATALOG_RECORD,
  UPDATE_CATALOG_RECORD_SUCCEEDED,
  UPDATE_CATALOG_RECORD_FAILED,
  FETCH_LINE_TABLE_REQUESTED,
  FETCH_LINE_TABLE_SUCCEEDED,
  FETCH_LINE_TABLE_FAILED,
  SELECT_CATEGORY,
  SET_CATALOG_PAGE,
  SET_CATALOG_FILTER,
} from './actions-types';

export function fetchCatalog(payload) {
  return { type: CATALOG_FETCH_REQUESTED, payload };
}

export function getSpecs(payload) {
  return { type: SPECS_REQUESTED, payload };
}

export function restartSpecs() {
  return { type: RESTART_SPECS };
}

export function getSpecsSucceedeed(payload) {
  return { type: SPECS_SUCCEEDED, payload };
}

export function getSpecsFailed(payload) {
  return { type: SPECS_FAILED, payload };
}

export function fetchInstaller(payload) {
  return { type: INSTALLER_FETCH_REQUESTED, payload };
}

export function fetchInstallerSucceeded(payload) {
  return { type: INSTALLER_FETCH_SUCCEEDED, payload };
}

export function fetchInstallerFailed(payload) {
  return { type: INSTALLER_FETCH_FAILED, payload };
}

export function fetchCatalogSucceeded(payload) {
  return { type: CATALOG_FETCH_SUCCEEDED, payload };
}

export function fetchCatalogFailed(payload) {
  return { type: CATALOG_FETCH_FAILED, payload };
}

export function selectImeiGPS(payload) {
  return { type: SELECT_IMEI_GPS, payload };
}

export function selectGPS(payload) {
  return { type: SELECT_GPS, payload };
}

export function deleteSelectGPS(payload) {
  return { type: DELETE_SELECT_GPS, payload };
}

export function clearSelectGPS(payload) {
  return { type: CLEAR_SELECT_GPS, payload };
}

export function clearOneSelectGPS(payload) {
  return { type: CLEAR_ONE_SELECT_GPS, payload };
}

export function clearImeiGPSSelect(payload) {
  return { type: CLEAR_IMEI_GPS_SELECTED, payload };
}

export function clearTagSelect(payload) {
  return { type: CLEAR_SELECT_TAGS, payload };
}

export function modeRemoveGps(payload) {
  return { type: CHANGE_MODE_GPS, payload };
}

export function selectInstaller(payload) {
  return { type: SELECT_INSTALLER, payload };
}

export function selectBrand(payload) {
  return { type: SELECT_BRAND, payload };
}

export function selectLine(payload) {
  return { type: SELECT_LINE, payload };
}

export function selectCardClass(payload) {
  return { type: SELECT_CIRCULATION_CARD_CLASS, payload };
}

export function fetchFilters(payload) {
  return { type: FILTERS_FETCH_REQUESTED, payload };
}

export function fetchFiltersSucceeded(payload) {
  return { type: FILTERS_FETCH_SUCCEEDED, payload };
}

export function fetchFiltersFailed(payload) {
  return { type: FILTERS_FETCH_FAILED, payload };
}

export function fetchAreas(payload) {
  return { type: AREAS_FETCH_REQUESTED, payload };
}

export function fetchAreasSucceeded(payload) {
  return { type: AREAS_FETCH_SUCCEEDED, payload };
}

export function fetchAreasFailed(payload) {
  return { type: AREAS_FETCH_FAILED, payload };
}

export function fetchRoles(payload) {
  return { type: ROLES_FETCH_REQUESTED, payload };
}

export function fetchRolesSucceeded(payload) {
  return { type: ROLES_FETCH_SUCCEEDED, payload };
}

export function fetchRolesFailed(payload) {
  return { type: ROLES_FETCH_FAILED, payload };
}

export function fetchModules(payload) {
  return { type: MODULES_FETCH_REQUESTED, payload };
}

export function fetchModulesSucceeded(payload) {
  return { type: MODULES_FETCH_SUCCEEDED, payload };
}

export function fetchModulesFailed(payload) {
  return { type: MODULES_FETCH_FAILED, payload };
}

export function fetchRentalStatuses(payload) {
  return { type: RENTAL_STATUSES_FETCH_REQUESTED, payload };
}

export function fetchRentalStatusesSucceeded(payload) {
  return { type: RENTAL_STATUSES_FETCH_SUCCEEDED, payload };
}

export function fetchRentalStatusesFailed(payload) {
  return { type: RENTAL_STATUSES_FETCH_FAILED, payload };
}

export function fetchRentalFilter(payload) {
  return { type: RENTAL_FILTER_FETCH_REQUESTED, payload };
}

export function fetchRentalFilterSucceeded(payload) {
  return { type: RENTAL_FILTER_FETCH_SUCCEEDED, payload };
}

export function fetchRentalFilterFailed(payload) {
  return { type: RENTAL_FILTER_FETCH_FAILED, payload };
}

export function fetchCatalogTable(payload) {
  return { type: FETCH_CATALOG_TABLE_REQUESTED, payload };
}

export function fetchCatalogTableSucceeded(payload) {
  return { type: FETCH_CATALOG_TABLE_SUCCEEDED, payload };
}

export function fetchCatalogTableFailed(payload) {
  return { type: FETCH_CATALOG_TABLE_FAILED, payload };
}

export function fetchLineTable(payload) {
  return { type: FETCH_LINE_TABLE_REQUESTED, payload };
}

export function fetchLineTableSucceeded(payload) {
  return { type: FETCH_LINE_TABLE_SUCCEEDED, payload };
}

export function fetchLineTableFailed(payload) {
  return { type: FETCH_LINE_TABLE_FAILED, payload };
}

export function selectCategory(payload) {
  return { type: SELECT_CATEGORY, payload };
}

export function setCatalogPage(payload) {
  return { type: SET_CATALOG_PAGE, payload };
}

export function setCatalogParams(payload) {
  return { type: SET_CATALOG_FILTER, payload };
}

export function createCatalogRecord(payload) {
  return { type: CREATE_CATALOG_RECORD, payload };
}

export function createCatalogRecordSucceeded(payload) {
  return { type: CREATE_CATALOG_RECORD_SUCCEEDED, payload };
}

export function createCatalogRecordFailed(payload) {
  return { type: CREATE_CATALOG_RECORD_FAILED, payload };
}

export function updateCatalogRecord(payload) {
  return { type: UPDATE_CATALOG_RECORD, payload };
}

export function updateCatalogRecordSucceeded(payload) {
  return { type: UPDATE_CATALOG_RECORD_SUCCEEDED, payload };
}

export function updateCatalogRecordFailed(payload) {
  return { type: UPDATE_CATALOG_RECORD_FAILED, payload };
}

export function closeResponseDialog() {
  return { type: CLOSE_RESPONSE_DIALOG };
}
