import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Formik } from 'formik';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { Layout, Icons, Button, Accordion } from '@casanova/casanova-common';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import Breadcrumb from 'containers/Breadcrumb';
import { UserInfo, RoleVerifier } from 'components';
import {
  VIEW_VEHICLE_CONVERSION,
  VIEW_VEHICLE_ADAPTATION,
  VIEW_VEHICLE_ADDITIONALS_SECTION,
  VIEW_VEHICLE_PURCHASE_SECTION,
  VIEW_VEHICLE_PERMISSIONS,
} from 'utils/roles/permissions';
import {
  VehicleForm,
  PurchaseForm,
  AdaptationForm,
  ConversionForm,
  PermissionForm,
} from '@vehicles/sections';
import { PurchaseOnlySchema } from '@vehicles/sections/PurchaseForm/schema';
import { isRegisterSinister } from '@vehicles/common/navigation';
import {
  AdditionalPlate,
  AdditionalRoadTax,
  AdditionalCirculationCard,
  AdditionalVerification,
  AdditionalFine,
  AdditionalPolicy,
  AdditionalCollision,
  AdditionalGPS,
  AdditionalKeys,
} from './additionals';

import {
  VehicleDataSchema,
  initialTouchedPurchase,
  initialTouchedVehicle,
  ConversionSchema,
} from './schema';

const VehicleEditor = ({
  history,
  match,
  location,
  fetchCatalog,
  vehicle,
  confirmationMessage,
  resultMessage,
  openConfirmationMessage,
  closeConfirmationMessage,
  closeResultMessage,
  storeVehicleData,
  fetchVehicleInfo,
  loader,
  editVehicleRequested,
  cleanVehicleData,
  selectBrand,
  selectLine,
  listBrands,
  exportVehicleDetail,
  policy,
  policySuccess,
  cleanAdditional,
}) => {
  const [action, setAction] = useState('add');
  const [showNotValidSinister, setShowNotValidSinister] = useState(null);
  const [accordionActive, setAccordionActive] = useState(
    isRegisterSinister() ? 'sinister' : null
  );
  const sinisterRef = useRef(null);
  const policyRef = useRef(null);

  useEffect(() => {
    fetchCatalog('vehicle-purpose');
    fetchCatalog('brand');
    fetchCatalog('model');
    fetchCatalog('color');
    fetchCatalog('vehicle-type');
    fetchCatalog('payment-type');
    fetchCatalog('provider');
    fetchCatalog('origin');
    fetchCatalog('fuel-type');
    fetchCatalog('owner');
    fetchCatalog('adaptation-origin');
    fetchCatalog('adaptation-type');
    fetchCatalog('line');
    fetchCatalog('version');
    fetchCatalog('conversion-type');
    fetchCatalog('permission-type');
    fetchCatalog('transmission-type');
    fetchCatalog('seat-type');
    fetchCatalog('conversion-origin');
    cleanVehicleData();
    cleanAdditional();
  }, [fetchCatalog, cleanVehicleData, cleanAdditional]);

  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { element } = params;

  const handleSaveEdit = useCallback(
    (validate, values) => {
      validate().then((errors) => {
        if (Object.keys(errors).length === 0 && errors.constructor === Object) {
          storeVehicleData({ vehicle: values, vehicleName: '' });
          openConfirmationMessage();
        }
      });
    },
    [openConfirmationMessage, storeVehicleData]
  );

  const handleConfirmAction = useCallback(() => {
    const hasFetchVehicleInfo = resultMessage?.errorCode?.includes(
      'FETCH_VEHICLE_INFO'
    );

    if (hasFetchVehicleInfo) {
      history.push('/vehicle/');
      return;
    }

    let uuid;
    if (element === 'vehicle' || element === 'adaptation') {
      uuid = vehicle.vehicleUuid;
    } else if (element === 'purchase') {
      uuid = vehicle.purchaseUuid;
    }

    editVehicleRequested({ vehicle, uuid, element });
  }, [
    resultMessage.errorCode,
    history,
    editVehicleRequested,
    vehicle,
    element,
  ]);

  const handleSuccessAction = useCallback(() => {
    history.push(`/vehicle/${match.params.id}/detail`);
  }, [history, match]);

  const handleOnEdit = useCallback(
    (section) => {
      history.push(`/vehicle/${match.params.id}/edit?element=${section}`);
    },
    [history, match]
  );

  const handleOnCancel = useCallback(() => {
    history.push(`/vehicle/${match.params.id}/detail`);
  }, [history, match]);

  const handleClickDownload = useCallback(() => {
    exportVehicleDetail(match.params.id);
  }, [exportVehicleDetail, match.params.id]);

  const handleCloseNotValidSinister = useCallback(() => {
    history.push('/vehicle/');
  }, [history]);

  const handleActionNotValidSinister = useCallback(() => {
    policyRef?.current?.scrollIntoView(true);
    setShowNotValidSinister(false);
  }, [policyRef]);

  useEffect(() => {
    const edit = match.path.indexOf('edit') !== -1;
    setAction(edit ? 'edit' : 'view');
    fetchVehicleInfo(match.params.id);
  }, [match, fetchVehicleInfo, fetchCatalog]);
  const vehicleId = _get(match, 'params.id', null);

  useEffect(() => {
    if (
      listBrands &&
      listBrands.length > 0 &&
      vehicle &&
      vehicle.brand &&
      vehicle.line
    ) {
      selectBrand({ target: { value: vehicle.brand } });
      selectLine({ target: { value: vehicle.line } });
    }
  }, [vehicle, selectBrand, selectLine, listBrands]);

  useEffect(() => {
    if (
      sinisterRef.current &&
      isRegisterSinister() &&
      showNotValidSinister === null &&
      policySuccess
    ) {
      setAccordionActive('sinister');
      sinisterRef.current.scrollIntoView(true);
    }
  }, [sinisterRef.current, showNotValidSinister, policySuccess]);

  useEffect(() => {
    if (
      policySuccess &&
      isRegisterSinister() &&
      _isEmpty(policy) &&
      showNotValidSinister === null
    ) {
      setAccordionActive('policy');
      setShowNotValidSinister(true);
    }
  }, [policySuccess, policy, showNotValidSinister]);

  useEffect(
    () => () => {
      cleanVehicleData();
      cleanAdditional();
    },
    [cleanVehicleData, cleanAdditional]
  );

  return (
    <>
      <ActionModal
        open={showNotValidSinister}
        icon={<Icons.IcoClientesWarning />}
        onClose={handleCloseNotValidSinister}
        onAction={handleActionNotValidSinister}
        title="No se tiene póliza"
        message="Para registrar un siniestro el vehículo debe tener una póliza de seguro activa"
        actionLabel="Registrar póliza"
        closeButton
      />
      <ActionModal
        open={resultMessage.open}
        icon={
          resultMessage.success ? <Icons.IcoCarOk /> : <Icons.IcoCarError />
        }
        onClose={closeResultMessage}
        onAction={
          resultMessage.success ? handleSuccessAction : handleConfirmAction
        }
        title={resultMessage.title}
        actionLabel={resultMessage.actionLabel}
        message={resultMessage.message}
        modalType="confirmation"
        code={resultMessage.errorCode}
        closeButton
      />
      <ActionModal
        open={confirmationMessage}
        icon={<Icons.StatusWarning />}
        onClose={closeConfirmationMessage}
        onAction={handleConfirmAction}
        title="CONFIRMACIÓN DE EDICIÓN"
        actionLabel="Sí, continuar"
        closeButton
        message="¿Estás seguro que quieres continuar?"
      />
      <Layout.Title
        label={<Breadcrumb />}
        right={
          action !== 'add' && (
            <Button onClick={handleClickDownload} outline>
              Descargar información
            </Button>
          )
        }
      />
      <Layout.Body showLoader={loader}>
        <div className="section">
          <legend className="section__title">INFORMACIÓN GENERAL</legend>
        </div>
        <hr style={{ marginTop: '0' }} width="100%" />
        <div className="form_container">
          <Formik
            initialValues={{ action, ...vehicle }}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={VehicleDataSchema}
            initialTouched={initialTouchedVehicle}
          >
            {({ values, validateForm, dirty }) => (
              <>
                <VehicleForm
                  action={
                    action === 'edit' && element === 'vehicle' ? 'edit' : 'view'
                  }
                  onEdit={() => handleOnEdit('vehicle')}
                  onCancelEdit={handleOnCancel}
                  onSaveEdit={() => {
                    handleSaveEdit(validateForm, values);
                  }}
                />
                <br />
                <RoleVerifier identifier={VIEW_VEHICLE_ADAPTATION}>
                  <AdaptationForm
                    onEdit={() => handleOnEdit('adaptation')}
                    onCancelEdit={handleOnCancel}
                    onSaveEdit={() => {
                      handleSaveEdit(validateForm, values);
                    }}
                    dirty={dirty}
                    action={
                      action === 'edit' && element === 'adaptation'
                        ? 'edit'
                        : 'view'
                    }
                  />
                </RoleVerifier>
              </>
            )}
          </Formik>
          <Formik
            initialValues={{ action, ...vehicle }}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={ConversionSchema}
            initialTouched={initialTouchedVehicle}
          >
            {({ values, validateForm, dirty }) => (
              <>
                <RoleVerifier identifier={VIEW_VEHICLE_CONVERSION}>
                  <ConversionForm
                    onEdit={() => handleOnEdit('conversion')}
                    onCancelEdit={handleOnCancel}
                    onSaveEdit={() => {
                      handleSaveEdit(validateForm, values);
                    }}
                    dirty={dirty}
                    action={
                      action === 'edit' && element === 'conversion'
                        ? 'edit'
                        : 'view'
                    }
                  />
                </RoleVerifier>
              </>
            )}
          </Formik>
          <br />
          <RoleVerifier identifier={VIEW_VEHICLE_PURCHASE_SECTION}>
            <Formik
              initialValues={vehicle}
              enableReinitialize
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={PurchaseOnlySchema}
              initialTouched={initialTouchedPurchase}
            >
              {({ values, validateForm }) => (
                <PurchaseForm
                  onEdit={() => handleOnEdit('purchase')}
                  onCancelEdit={handleOnCancel}
                  onSaveEdit={() => handleSaveEdit(validateForm, values)}
                  action={
                    action === 'edit' && element === 'purchase'
                      ? 'edit'
                      : 'view'
                  }
                />
              )}
            </Formik>
          </RoleVerifier>
          <UserInfo {...vehicle.purchaseActions} />
        </div>
        <br />
        <RoleVerifier identifier={VIEW_VEHICLE_PERMISSIONS}>
          <Formik
            initialValues={vehicle}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={PurchaseOnlySchema}
            initialTouched={initialTouchedPurchase}
          >
            {({ values, validateForm }) => (
              <PermissionForm
                onEdit={() => handleOnEdit('permission')}
                onCancelEdit={handleOnCancel}
                onSaveEdit={() => handleSaveEdit(validateForm, values)}
                action={
                  action === 'edit' && element === 'permission'
                    ? 'edit'
                    : 'view'
                }
              />
            )}
          </Formik>
          <UserInfo {...vehicle} />
        </RoleVerifier>
        <br />
        <RoleVerifier identifier={VIEW_VEHICLE_ADDITIONALS_SECTION}>
          <div className="section">
            <legend className="section__title">INFORMACIÓN ADICIONAL</legend>
          </div>
          <hr style={{ marginTop: '0' }} width="100%" />

          <Accordion active={accordionActive}>
            <AdditionalPlate name="plate" vehicleId={vehicleId} />
            {/* <AdditionalRoadTax name="roadTax" vehicleId={vehicleId} /> */}
            {/* <AdditionalCirculationCard
              name="circulationCard"
              vehicleId={vehicleId}
            /> */}
            {/* <AdditionalVerification name="verification" vehicleId={vehicleId} /> */}
            {/* <AdditionalFine name="fine" vehicleId={vehicleId} /> */}
            {/* <AdditionalPolicy
              name="policy"
              vehicleId={vehicleId}
              customRef={policyRef}
            /> */}
            {/* <AdditionalCollision
              name="sinister"
              vehicleId={vehicleId}
              customRef={sinisterRef}
            /> */}
            <AdditionalGPS name="gps" vehicleId={vehicleId} />
            {/* <AdditionalKeys name="key" vehicleId={vehicleId} /> */}
          </Accordion>
        </RoleVerifier>
      </Layout.Body>
    </>
  );
};

export default VehicleEditor;
