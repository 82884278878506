import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import BrandModal from './BrandModal';
import { closeResponseDialog } from 'store/catalogs/actions';

export default compose(
  connect(
    (state) => ({
      loader: state.commons.loader,
      responseDialog: state.catalogs.responseDialog,
    }),
    { closeResponseDialog }
  )
)(BrandModal);
