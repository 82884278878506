import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  MaskedInput as Input,
  Loader,
  Section,
  withForm,
  Select,
} from '@casanova/casanova-common';
import { Blank } from 'components';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import i18n from '@i18n';
import _noop from 'lodash/noop';
import { config as finalConfig } from './config';
import { useFormikContext } from 'formik';

const { ADD, EDIT } = FORM_ACTIONS;

const DetailLine = (props) => {
  const {
    fetchCatalog,
    listBrands = [],
    selectedLine = {},
    action = ADD,
    type,
    selectCategory,
    listCategory,
    typesBySelectedCategory,
  } = props;

  const { setFieldValue, values } = useFormikContext();

  const usedCategories = listCategory.filter(
    (c) =>
      c.value === '297b6a1f-d612-4734-aa14-86410e70c330' ||
      c.value === '7422472e-b516-42dc-86a1-84b3706d38dd'
  );

  const title = `${action === EDIT ? i18n('COMMONS__EDIT') : 'Nueva'} Linea`;

  const handleSelectCategory = useCallback(
    (e) => {
      setFieldValue('typeByCategory', undefined);
      selectCategory(e.target.value);
    },
    [selectCategory, setFieldValue]
  );

  useEffect(() => {
    setFieldValue('action', action);
    setFieldValue('type', type);
    if (action === EDIT) {
      selectCategory(selectedLine.typeByCategoryUuid);
      setFieldValue('name', selectedLine.name);
      setFieldValue('uuid', selectedLine.uuid);
      setFieldValue('brand', selectedLine.brandUuid);
      setFieldValue('category', selectedLine.typeByCategoryUuid);
      setFieldValue('typeByCategory', selectedLine.categoryUuid);
    }
  }, [action, selectedLine, setFieldValue]);

  useEffect(() => {
    ['brand', 'vehicle-category'].forEach(fetchCatalog);
  }, [fetchCatalog]);

  return (
    <>
      <Section title={title}>
        <div className="row">
          <div className="col-4">
            <Input
              label={`${i18n('COMMONS__LINE')}*`}
              placeholder={`${i18n('COMMONS__LINE')}*`}
              name="name"
              upperCase
            />
          </div>
          <div className="col-4">
            <Select
              label={`${i18n('COMMONS__BRAND')}*`}
              placeholder={`${i18n('COMMONS__BRAND')}*`}
              name="brand"
              options={listBrands}
            />
          </div>
          <div className="col-4">
            <Select
              label="Tipo*"
              placeholder="Tipo*"
              name="category"
              onChange={handleSelectCategory}
              options={usedCategories}
            />
          </div>
          <div className="col-4">
            <Select
              label="Categoría*"
              placeholder="Categoría*"
              name="typeByCategory"
              options={typesBySelectedCategory}
            />
          </div>
        </div>
      </Section>
    </>
  );
};

const LineModal = (props) => {
  const {
    show = false,
    onClose = _noop,
    onSuccess,
    loader = false,
    selectedLine = {},
    closeResponseDialog,
    responseDialog,
    action = ADD,
  } = props;

  const [hide, setHide] = useState(false);
  const [shouldDisabledSubmit, setShouldDisabledSubmit] = useState(false);

  const handleBeforeSubmit = useCallback(() => {
    setHide(true);
  }, []);

  const handleSuccess = useCallback(() => {
    setHide(false);
    onSuccess();
  }, []);

  const handleClose = useCallback(() => {
    setHide(false);
    onClose();
  }, []);

  const config = useMemo(() => {
    return {
      ...finalConfig(action),
      onBeforeSubmit: handleBeforeSubmit,
      onSuccess: handleSuccess,
      onClose: handleClose,
    };
  }, [action, handleBeforeSubmit, handleSuccess]);

  const DetailLineWithForm = withForm({ config })(DetailLine)({
    ...props,
    selectedLine,
    layout: false,
    left: <Blank />,
    onCancel: handleClose,
    closeResponseDialog: () => {
      if (responseDialog.open) {
        handleSuccess();
      }
      closeResponseDialog();
    },
    disabledSubmit: shouldDisabledSubmit,
    shouldDisabledSubmit,
    setShouldDisabledSubmit,
  });

  return (
    <>
      {/* <Loader show={hide && !loader && show} /> */}
      <Modal
        open={!loader && show}
        header={null}
        footer={null}
        onClose={onClose}
        topContainerClassName={`${hide ? 'd-none' : ''}`}
        closeButton
        bigModal
      >
        {DetailLineWithForm}
      </Modal>
    </>
  );
};

export default LineModal;
