import _get from 'lodash/get';
import {
  transformVehicleHistoricDetails,
  transformVehicleLoans,
  transformVehicleTransfers,
  transformVehicleContracts,
  transformVehicleVirtualContracts,
} from '@vehicles/common/transformers';
import {
  MASSIVE_LOAD_TYPES,
  MASSIVE_CHANGE_TYPES,
} from '@vehicles/common/constants';
import {
  VEHICLES_FETCH_ALL,
  VEHICLES_FETCH_REQUESTED,
  VEHICLES_FETCH_SUCCEEDED,
  VEHICLES_FETCH_FAILED,
  VEHICLES_FETCH_FILTER,
  VEHICLES_FETCH_FILTER_SUCCEEDED,
  VEHICLES_FETCH_FILTER_FAILED,
  SET_PAGE_TABLE_VEHICLES,
  SHOW_CONFIRMATION_MESSAGE,
  CLOSE_CONFIRMATION_MESSAGE,
  CLOSE_RESULT_MESSAGE,
  STORE_VEHICLE_DATA,
  SAVE_VEHICLE_REQUESTED,
  SAVE_VEHICLE_SUCCEEDED,
  SAVE_VEHICLE_FAILED,
  EDIT_VEHICLE_REQUESTED,
  EDIT_VEHICLE_SUCCEEDED,
  EDIT_VEHICLE_FAILED,
  UPLOAD_VEHICLES_SUCCEEDED,
  UPLOAD_MASSIVE_CHANGE_SUCCEEDED,
  MODIFY_FILTERS,
  FETCH_VEHICLE_INFO_REQUESTED,
  FETCH_VEHICLE_INFO_SUCCEEDED,
  FETCH_VEHICLE_INFO_FAILED,
  SET_VEHICLES_SEARCH,
  CLEAN_VEHICLE_DATA,
  CLEAN_ADDITIONAL,
  SET_MASSIVE_LOAD_TYPE,
  SET_MASSIVE_CHANGE_TYPE,
  UPLOAD_VEHICLES,
  UPLOAD_VEHICLES_FAILED,
  UPLOAD_MASSIVE_CHANGE,
  UPLOAD_MASSIVE_CHANGE_FAILED,
  GET_ADDITIONAL,
  GET_ADDITIONAL_SUCCEED,
  ADD_NEW_ADDITIONAL,
  CANCEL_NEW_ADDITIONAL,
  SAVE_ADDITIONAL,
  SAVE_ADDITIONAL_SUCCEED,
  SAVE_ADDITIONAL_FAILED,
  CLOSE_ADDITIONAL_RESULT,
  RESET,
  GET_TYPES,
  GET_TYPES_SUCCEEDED,
  // GET_TYPES_FAILED,
  VEHICLE_STATUS_REQUESTED,
  VEHICLE_STATUS_SUCCEEDED,
  VEHICLE_STATUS_FAILED,
  SET_PAGE_VEHICLE_STATUS,
  VEHICLE_CONTRACTS_REQUESTED,
  VEHICLE_CONTRACTS_SUCCEEDED,
  VEHICLE_CONTRACTS_FAILED,
  SET_PAGE_VEHICLE_CONTRACTS,
  VEHICLE_LOANS_REQUESTED,
  VEHICLE_LOANS_SUCCEEDED,
  VEHICLE_LOANS_FAILED,
  SET_PAGE_VEHICLE_LOANS,
  VEHICLE_TRANSFERS_REQUESTED,
  VEHICLE_TRANSFERS_SUCCEEDED,
  VEHICLE_TRANSFERS_FAILED,
  SET_PAGE_VEHICLE_TRANSFERS,
  EDIT_VEHICLE_DETAILS_REQUESTED,
  EDIT_VEHICLE_DETAILS_SUCCEEDED,
  EDIT_VEHICLE_DETAILS_FAILED,
  CLOSE_RESPONSE_DIALOG,
  CLOSE_RESPONSE_DIALOG_VC,
  FETCH_VEHICLE_DETAILS_REQUESTED,
  FETCH_VEHICLE_DETAILS_SUCCEEDED,
  FETCH_VEHICLE_DETAILS_FAILED,
  CLEAN_VEHICLE_DETAILS,
  MARK_VEHICLE_FOR_SALE_REQUESTED,
  MARK_VEHICLE_FOR_SALE_SUCCEEDED,
  MARK_VEHICLE_FOR_SALE_FAILED,
  SEARCH_VEHICLES,
  SEARCH_VEHICLES_SUCCEEDED,
  SEARCH_VEHICLES_FAILED,
  CLEAN_SEARCH_VEHICLES,
  VEHICLES_UPDATE_LIST,
  EXPORT_CATALOG_RECORDS,
  EXPORT_CATALOG_RECORDS_SUCCEEDED,
  EXPORT_CATALOG_RECORDS_FAILED,
  VEHICLE_VIRTUAL_CONTRACTS_REQUESTED,
  VEHICLE_VIRTUAL_CONTRACT_SUCCEEDED,
  VEHICLE_VIRTUAL_CONTRACT_FAILED,
  SET_PAGE_VEHICLE_VIRTUAL_CONTRACT,
} from './actions-types';

const initialState = {
  loader: false,
  massiveLoadType: MASSIVE_LOAD_TYPES.VEHICLES,
  massiveChangeType: MASSIVE_CHANGE_TYPES.GENERAL,
  allVehicles: {},
  filters: {
    vehiclesList: {
      statuses: [],
      substatuses: [],
    },
  },
  vehiclesList: {
    params: {
      page: 0,
      size: 10,
      property: 'id',
      direction: 'asc',
    },
    searchPattern: '',
    filters: [],
    totalPages: 1,
    totalElements: 10,
    results: [
      {
        page: 0,
        list: [],
        loading: false,
        loaded: false,
      },
    ],
  },
  confirmationMessage: false,
  resultMessage: {
    success: false,
    message: '',
    errorCode: '',
    open: false,
    actionLabel: '',
    title: '',
  },
  vehicleData: {},
  vehicleName: '',
  massiveRecords: {
    success_records: [],
    failed_records: [],
    process: false,
  },
  massiveChangeRecords: {
    success_records: [],
    failed_records: [],
    process: false,
  },
  additionals: {
    plate: null,
  },
  types: {
    list: [],
  },
  responseDialog: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
  },
  vehicleStatus: {
    params: {
      page: 0,
      size: 5,
    },
    searchPattern: '',
    filters: [],
    totalPages: 1,
    totalElements: 10,
    results: [
      {
        page: 0,
        list: [],
        loading: false,
        success: false,
        error: false,
      },
    ],
  },
  vehicleContracts: {
    params: {
      page: 0,
      size: 5,
    },
    searchPattern: '',
    filters: [],
    totalPages: 1,
    totalElements: 10,
    results: [
      {
        page: 0,
        list: [],
        loading: false,
        success: false,
        error: false,
      },
    ],
  },
  vehicleLoans: {
    params: {
      page: 0,
      size: 5,
    },
    searchPattern: '',
    filters: [],
    totalPages: 1,
    totalElements: 10,
    results: {
      page: 0,
      list: [],
      loading: false,
      success: false,
      error: false,
    },
  },
  vehicleTransfers: {
    params: {
      page: 0,
      size: 5,
    },
    searchPattern: '',
    filters: [],
    totalPages: 1,
    totalElements: 10,
    results: {
      page: 0,
      list: [],
      loading: false,
      success: false,
      error: false,
    },
  },
  virtualContracts: {
    params: {
      page: 0,
      size: 5,
    },
    searchPattern: '',
    filters: [],
    totalPages: 1,
    totalElements: 10,
    results: {
      page: 0,
      list: [],
      loading: false,
      success: false,
      error: false,
    },
  },
  vehicleDetails: {
    data: {},
    success: false,
    error: false,
    errorMessage: '',
  },
  editVehicleDetails: {
    data: {},
    success: false,
    error: false,
    errorMessage: '',
  },
  markVehicleForSale: {
    data: {},
    success: false,
    error: false,
    errorMessage: '',
  },
  vehiclesByPlate: {
    responseDialog: {
      open: false,
      success: false,
      errorCode: '',
      errorMessage: '',
    },
    error: false,
    errorMessage: '',
    list: [],
  },
  exportCatalogRecords: {
    success: false,
    loading: false,
  },
};

export function vehiclesReducer(state = initialState, action) {
  let errorCode = '';
  if (action && action.payload && action.payload.data) {
    errorCode = action.payload.data.errorCode;
  }

  switch (action.type) {
    case GET_ADDITIONAL: {
      const { payload } = action;
      const { name } = payload;
      const currentData = _get(state, `additionals.${name}`);

      return {
        ...state,
        additionals: {
          ...state.additionals,
          [name]: {
            ...currentData,
            loading: true,
            success: false,
            data: null,
          },
        },
      };
    }
    case VEHICLES_FETCH_ALL: {
      return { ...state };
    }
    case ADD_NEW_ADDITIONAL: {
      const { payload } = action;
      const { name } = payload;
      const currentData = _get(state, `additionals.${name}`);

      return {
        ...state,
        loader: false,
        additionals: {
          ...state.additionals,
          [name]: {
            ...currentData,
            addNew: true,
          },
        },
      };
    }
    case CANCEL_NEW_ADDITIONAL: {
      const {
        payload: { name },
      } = action;
      const currentData = _get(state, `additionals.${name}`);
      return {
        ...state,
        additionals: {
          ...state.additionals,
          [name]: {
            ...currentData,
            addNew: false,
          },
        },
      };
    }
    case GET_ADDITIONAL_SUCCEED: {
      const { payload } = action;
      const { name, data } = payload;
      const currentData = _get(state, `additionals.${name}`);

      return {
        ...state,
        additionals: {
          ...state.additionals,
          [name]: {
            ...currentData,
            loading: false,
            success: true,
            data,
          },
        },
      };
    }

    case SAVE_ADDITIONAL: {
      const { payload } = action;
      const { name } = payload;
      const currentData = _get(state, `additionals.${name}`);

      return {
        ...state,
        loader: true,
        additionals: {
          ...state.additionals,
          [name]: {
            ...currentData,
            saved: false,
            resultMessage: null,
            addNew: false,
          },
        },
      };
    }
    case SAVE_ADDITIONAL_SUCCEED: {
      const { payload } = action;
      const { resultMessage, name, data } = payload;
      const currentData = _get(state, `additionals.${name}`);

      return {
        ...state,
        loader: false,
        additionals: {
          ...state.additionals,
          [name]: {
            ...currentData,
            saved: false,
            data,
            resultMessage: {
              ...resultMessage,
              success: true,
              message: resultMessage,
              open: name === 'gps',
            },
          },
        },
      };
    }

    case SAVE_ADDITIONAL_FAILED: {
      const { payload } = action;
      const { name, resultMessage } = payload;
      const currentData = _get(state, `additionals.${name}`);

      return {
        ...state,
        loader: false,
        additionals: {
          ...state.additionals,
          [name]: {
            ...currentData,
            saved: false,
            resultMessage: {
              success: false,
              open: true,
              title: '¡UPS! ALGO PASÓ!',
              actionLabel: 'Reintentar',
              ...resultMessage,
            },
          },
        },
      };
    }

    case CLOSE_ADDITIONAL_RESULT: {
      const { payload } = action;
      const { name } = payload;
      const currentData = _get(state, `additionals.${name}`);
      return {
        ...state,
        additionals: {
          ...state.additionals,
          [name]: {
            ...currentData,
            resultMessage: null,
          },
        },
      };
    }

    case CLEAN_VEHICLE_DATA:
      return {
        ...state,
        confirmationMessage: false,
        resultMessage: {
          success: false,
          message: '',
          errorCode: '',
          open: false,
          actionLabel: '',
          title: '',
        },
        vehicleData: {},
      };

    case CLEAN_ADDITIONAL:
      return {
        ...state,
        additionals: initialState.additionals,
      };

    case SET_PAGE_TABLE_VEHICLES:
      return {
        ...state,
        vehiclesList: {
          ...state.vehiclesList,
          params: {
            ...state.vehiclesList.params,
            page: action.payload,
          },
        },
      };
    case SHOW_CONFIRMATION_MESSAGE:
      return {
        ...state,
        confirmationMessage: true,
      };
    case CLOSE_CONFIRMATION_MESSAGE:
      return {
        ...state,
        confirmationMessage: false,
      };
    case CLOSE_RESULT_MESSAGE:
      return {
        ...state,
        resultMessage: {
          ...state.resultMessage,
          open: false,
        },
      };
    case STORE_VEHICLE_DATA:
      return {
        ...state,
        vehicleData: action.payload.vehicle,
        vehicleName: action.payload.vehicleName,
      };
    case VEHICLES_FETCH_REQUESTED:
      return {
        ...state,
        resultMessage: {
          ...state.resultMessage,
          open: false,
        },
        vehiclesList: {
          ...state.vehiclesList,
          results: {
            page: state.vehiclesList.params.page,
            list: [],
            loading: true,
            loaded: false,
          },
        },
        responseDialog: initialState.responseDialog,
      };
    case VEHICLES_FETCH_SUCCEEDED:
      const {
        content,
        totalElements,
        totalPages,
        first,
      } = action.payload.data.data;
      return {
        ...state,
        vehiclesList: {
          ...state.vehiclesList,
          results: {
            list: content.map((element, idx) => {
              element.id = idx;
              return element;
            }),
            loading: false,
            loaded: true,
            page: first ? 0 : state.vehiclesList.params.page,
          },
          totalPages,
          totalElements,
        },
      };
    case VEHICLES_FETCH_FAILED:
      return {
        ...state,
        vehiclesList: {
          ...state.vehiclesList,
          results: {
            list: [],
            loading: false,
            loaded: false,
          },
        },
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          errorMessage: action.payload,
        },
      };
    case VEHICLES_FETCH_FILTER:
      return {
        ...state,
        filters: initialState.filters,
        resultMessage: {
          success: false,
          message: '',
          errorCode: '',
          open: false,
          actionLabel: '',
          title: '',
        },
      };
    case VEHICLES_FETCH_FILTER_SUCCEEDED:
      return {
        ...state,
        filters: {
          ...state.filters,
          vehiclesList: action.payload,
        },
      };
    case VEHICLES_FETCH_FILTER_FAILED:
      return {
        ...state,
        filters: initialState.filters,
        resultMessage: {
          success: false,
          message: action.payload.message,
          errorCode: action.payload.errorCode,
          open: true,
          title: '¡UPS! ALGO PASÓ!',
          actionLabel: 'Reintentar',
        },
      };

    case VEHICLES_UPDATE_LIST:
      return {
        ...state,
        vehiclesList: {
          ...state.vehiclesList,
          results: {
            ...state.vehiclesList.results,
            list: action.payload,
          },
        },
      };
    case SAVE_VEHICLE_REQUESTED:
    case EDIT_VEHICLE_REQUESTED:
      return {
        ...state,
        loader: true,
        confirmationMessage: false,
        resultMessage: {
          ...state.resultMessage,
          success: false,
          open: false,
          message: '',
          errorCode: '',
        },
      };
    case SAVE_VEHICLE_FAILED:
    case EDIT_VEHICLE_FAILED:
      return {
        ...state,
        loader: false,
        resultMessage: {
          ...state.resultMessage,
          title: '¡UPS! ALGO PASÓ!',
          actionLabel: 'Reintentar',
          success: false,
          open: true,
          errorCode,
          message:
            'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
        },
      };
    case SAVE_VEHICLE_SUCCEEDED:
      return {
        ...state,
        loader: false,
        resultMessage: {
          ...state.resultMessage,
          title: '¡BIEN HECHO!',
          actionLabel: 'Ir a Vehículos',
          success: true,
          errorCode: '',
          message: `Se agregó el ${state.vehicleName} de forma exitosa al catálogo, si deseas realizar algún cambio tendrás que buscarlo y editarlo manualmente.`,
          open: true,
        },
      };
    case EDIT_VEHICLE_SUCCEEDED:
      return {
        ...state,
        loader: false,
        resultMessage: {
          ...state.resultMessage,
          title: '¡BIEN HECHO!',
          actionLabel: 'Ir a detalle',
          success: true,
          errorCode: '',
          message: `Se editó el vehículo de forma exitosa.`,
          open: true,
        },
      };
    case SET_MASSIVE_LOAD_TYPE:
      return {
        ...state,
        massiveLoadType: action.payload,
      };
    case SET_MASSIVE_CHANGE_TYPE:
      return {
        ...state,
        massiveChangeType: action.payload,
      };
    case UPLOAD_VEHICLES:
      return {
        ...state,
        loader: true,
        massiveRecords: {
          ...state.massiveRecords,
          process: action.payload.process,
        },
        resultMessage: {
          ...state.resultMessage,
          success: true,
          open: false,
        },
      };
    case UPLOAD_VEHICLES_SUCCEEDED:
      return {
        ...state,
        loader: false,
        resultMessage: {
          ...state.resultMessage,
          success: true,
          open: true,
        },
        massiveRecords: {
          ...state.massiveRecords,
          ...action.payload.data,
        },
      };
    case UPLOAD_VEHICLES_FAILED:
      return {
        ...state,
        loader: false,
        resultMessage: {
          ...state.resultMessage,
          success: false,
          errorCode,
          open: true,
        },
        massiveRecords: {
          ...state.massiveRecords,
          success_records: [],
          failed_records: [],
        },
      };
    case UPLOAD_MASSIVE_CHANGE:
      return {
        ...state,
        loader: true,
        massiveChangeRecords: {
          ...state.massiveChangeRecords,
          process: action.payload.process,
        },
        resultMessage: {
          ...state.resultMessage,
          success: true,
          open: false,
        },
      };
    case UPLOAD_MASSIVE_CHANGE_SUCCEEDED:
      return {
        ...state,
        loader: false,
        resultMessage: {
          ...state.resultMessage,
          success: true,
          open: true,
        },
        massiveChangeRecords: {
          ...state.massiveChangeRecords,
          ...action.payload.data,
        },
      };
    case UPLOAD_MASSIVE_CHANGE_FAILED:
      return {
        ...state,
        loader: false,
        resultMessage: {
          ...state.resultMessage,
          success: false,
          errorCode,
          open: true,
        },
        massiveChangeRecords: {
          ...state.massiveChangeRecords,
          success_records: [],
          failed_records: [],
        },
      };
    case MODIFY_FILTERS:
      return {
        ...state,
        vehiclesList: {
          ...state.vehiclesList,
          params: {
            ...state.vehiclesList.params,
            page: 0,
          },
          filters: action.payload,
        },
      };
    case FETCH_VEHICLE_INFO_REQUESTED:
      return {
        ...state,
        loader: true,
        vehicleData: {},
        resultMessage: {
          success: false,
          message: '',
          errorCode: '',
          open: false,
          actionLabel: '',
          title: '',
        },
      };
    case FETCH_VEHICLE_INFO_SUCCEEDED:
      return {
        ...state,
        loader: false,
        vehicleData: action.payload.data,
        resultMessage: {
          success: false,
          message: '',
          errorCode: '',
          open: false,
          actionLabel: '',
          title: '',
        },
      };
    case FETCH_VEHICLE_INFO_FAILED:
      return {
        ...state,
        loader: false,
        vehicleData: {},
        resultMessage: {
          ...state.resultMessage,
          title: '¡UPS! ALGO PASÓ!',
          actionLabel: 'Cerrar',
          success: false,
          open: true,
          errorCode: `${action.payload} - FETCH_VEHICLE_INFO`,
          message: 'No se pudo obtener la información del vehiculo',
        },
      };
    case SET_VEHICLES_SEARCH:
      return {
        ...state,
        vehiclesList: {
          ...state.vehiclesList,
          params: {
            ...state.vehiclesList.params,
            ...action.payload,
            page: 0,
          },
          searchPattern: action.payload.searchText,
        },
      };
    case GET_TYPES:
      return {
        ...state,
        types: {
          list: [],
        },
      };
    case GET_TYPES_SUCCEEDED:
      return {
        ...state,
        types: {
          list: action.payload,
        },
      };
    case VEHICLE_STATUS_REQUESTED:
      return {
        ...state,
        vehicleStatus: {
          ...state.vehicleStatus,
          results: {
            page: state.vehicleStatus.params.page,
            list: [],
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case VEHICLE_STATUS_SUCCEEDED:
      const vehicleStatus = action.payload;
      return {
        ...state,
        vehicleStatus: {
          ...state.vehicleStatus,
          results: {
            page: vehicleStatus.first ? 0 : state.vehicleStatus.params.page,
            list: transformVehicleHistoricDetails(vehicleStatus.content),
            loading: false,
            success: true,
            error: false,
          },
          totalPages: vehicleStatus.totalPages,
          totalElements: vehicleStatus.totalElements,
        },
      };
    case VEHICLE_STATUS_FAILED:
      return {
        ...state,
        vehicleStatus: {
          ...state.vehicleStatus,
          results: {
            list: [],
            loading: false,
            success: false,
            error: true,
          },
        },
      };
    case SET_PAGE_VEHICLE_STATUS:
      return {
        ...state,
        vehicleStatus: {
          ...state.vehicleStatus,
          params: {
            ...state.vehicleStatus.params,
            page: action.payload,
          },
        },
      };
    case VEHICLE_CONTRACTS_REQUESTED:
      return {
        ...state,
        vehicleContracts: {
          ...state.vehicleContracts,
          results: {
            page: state.vehicleContracts.params.page,
            list: [],
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case VEHICLE_CONTRACTS_SUCCEEDED:
      const vehicleContracts = action.payload;
      return {
        ...state,
        vehicleContracts: {
          ...state.vehicleContracts,
          results: {
            page: state.vehicleContracts.params.page,
            list: transformVehicleContracts(vehicleContracts.content),
            loading: false,
            success: true,
            error: false,
          },
          totalPages: vehicleContracts.totalPages,
          totalElements: vehicleContracts.totalElements,
        },
      };
    case VEHICLE_CONTRACTS_FAILED:
      return {
        ...state,
        vehicleContracts: {
          ...state.vehicleContracts,
          results: {
            list: [],
            loading: false,
            success: false,
            error: true,
          },
        },
      };
    case SET_PAGE_VEHICLE_CONTRACTS:
      return {
        ...state,
        vehicleContracts: {
          ...state.vehicleContracts,
          params: {
            ...state.vehicleContracts.params,
            page: action.payload,
          },
        },
      };
    case VEHICLE_LOANS_REQUESTED:
      return {
        ...state,
        vehicleLoans: {
          ...state.vehicleLoans,
          results: {
            page: state.vehicleLoans.params.page,
            list: [],
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case VEHICLE_LOANS_SUCCEEDED:
      const vehicleLoans = action.payload;
      const formattedLoansContent = transformVehicleLoans(vehicleLoans.content);

      return {
        ...state,
        vehicleLoans: {
          ...state.vehicleLoans,
          results: {
            page: vehicleLoans.first ? 0 : state.vehicleLoans.params.page,
            list: formattedLoansContent,
            loading: false,
            success: true,
            error: false,
          },
          totalPages: vehicleLoans.totalPages,
          totalElements: vehicleLoans.totalElements,
        },
      };
    case VEHICLE_LOANS_FAILED:
      return {
        ...state,
        vehicleLoans: {
          ...state.vehicleLoans,
          results: {
            list: [],
            loading: false,
            success: false,
            error: true,
          },
        },
      };
    case SET_PAGE_VEHICLE_LOANS:
      return {
        ...state,
        vehicleLoans: {
          ...state.vehicleLoans,
          params: {
            ...state.vehicleLoans.params,
            page: action.payload,
          },
        },
      };
    case VEHICLE_TRANSFERS_REQUESTED:
      return {
        ...state,
        vehicleTransfers: {
          ...state.vehicleTransfers,
          results: {
            page: state.vehicleTransfers.params.page,
            list: [],
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case VEHICLE_TRANSFERS_SUCCEEDED:
      const vehicleTransfers = action.payload;
      const formattedTransferContent = transformVehicleTransfers(
        vehicleTransfers.content
      );

      return {
        ...state,
        vehicleTransfers: {
          ...state.vehicleTransfers,
          results: {
            page: vehicleTransfers.first
              ? 0
              : state.vehicleTransfers.params.page,
            list: formattedTransferContent,
            loading: false,
            success: true,
            error: false,
          },
          totalPages: vehicleTransfers.totalPages,
          totalElements: vehicleTransfers.totalElements,
        },
      };
    case VEHICLE_TRANSFERS_FAILED:
      return {
        ...state,
        vehicleTransfers: {
          ...state.vehicleTransfers,
          results: {
            list: [],
            loading: false,
            success: false,
            error: true,
          },
        },
      };
    case SET_PAGE_VEHICLE_TRANSFERS:
      return {
        ...state,
        vehicleTransfers: {
          ...state.vehicleTransfers,
          params: {
            ...state.vehicleTransfers.params,
            page: action.payload,
          },
        },
      };
    case VEHICLE_VIRTUAL_CONTRACTS_REQUESTED:
      return {
        ...state,
        virtualContracts: {
          ...state.virtualContracts,
          results: {
            page: state.virtualContracts.params.page,
            list: [],
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case VEHICLE_VIRTUAL_CONTRACT_SUCCEEDED:
      const virtualContracts = action.payload;
      const formattedVirtualContracts = transformVehicleVirtualContracts(
        virtualContracts.content
      );

      return {
        ...state,
        virtualContracts: {
          ...state.virtualContracts,
          results: {
            page: virtualContracts.first
              ? 0
              : state.virtualContracts.params.page,
            list: formattedVirtualContracts,
            loading: false,
            success: true,
            error: false,
          },
          totalPages: virtualContracts.totalPages,
          totalElements: virtualContracts.totalElements,
        },
      };
    case VEHICLE_VIRTUAL_CONTRACT_FAILED:
      return {
        ...state,
        virtualContracts: {
          ...state.virtualContracts,
          results: {
            list: [],
            loading: false,
            success: false,
            error: true,
          },
        },
      };
    case SET_PAGE_VEHICLE_VIRTUAL_CONTRACT:
      return {
        ...state,
        virtualContracts: {
          ...state.virtualContracts,
          params: {
            ...state.virtualContracts.params,
            page: action.payload,
          },
        },
      };
    case EDIT_VEHICLE_DETAILS_REQUESTED:
      return {
        ...state,
        editVehicleDetails: {
          data: {},
          success: false,
          error: false,
          errorMessage: '',
        },
        responseDialog: {
          open: false,
          success: false,
          error: false,
          errorMessage: '',
        },
      };
    case EDIT_VEHICLE_DETAILS_SUCCEEDED:
      return {
        ...state,
        editVehicleDetails: {
          data: action.payload,
          success: true,
          error: false,
          errorMessage: '',
        },
        responseDialog: {
          open: true,
          success: true,
          error: false,
          errorMessage: '',
        },
      };
    case EDIT_VEHICLE_DETAILS_FAILED:
      return {
        ...state,
        editVehicleDetails: {
          data: {},
          success: false,
          error: true,
          errorMessage: action.payload,
        },
        responseDialog: {
          open: true,
          success: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    case CLOSE_RESPONSE_DIALOG:
      return {
        ...state,
        responseDialog: initialState.responseDialog,
      };
    case CLOSE_RESPONSE_DIALOG_VC:
      return {
        ...state,
        vehiclesByPlate: {
          ...state.vehiclesByPlate,
          responseDialog: initialState.responseDialog,
        },
      };
    case FETCH_VEHICLE_DETAILS_REQUESTED:
    case CLEAN_VEHICLE_DETAILS:
      return {
        ...state,
        vehicleDetails: {
          data: {},
          success: false,
          error: false,
          errorMessage: '',
        },
      };
    case FETCH_VEHICLE_DETAILS_SUCCEEDED:
      return {
        ...state,
        vehicleDetails: {
          data: action.payload,
          success: true,
          error: false,
          errorMessage: '',
        },
      };
    case FETCH_VEHICLE_DETAILS_FAILED:
      return {
        ...state,
        vehicleDetails: {
          data: {},
          success: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    case MARK_VEHICLE_FOR_SALE_REQUESTED:
      return {
        ...state,
        markVehicleForSale: {
          data: {},
          success: false,
          error: false,
          errorMessage: '',
        },
        responseDialog: {
          open: false,
          success: false,
          error: false,
          errorMessage: '',
        },
      };
    case MARK_VEHICLE_FOR_SALE_SUCCEEDED:
      return {
        ...state,
        markVehicleForSale: {
          data: action.payload,
          success: true,
          error: false,
          errorMessage: '',
        },
        responseDialog: {
          open: true,
          success: true,
          error: false,
          errorMessage: '',
        },
      };
    case MARK_VEHICLE_FOR_SALE_FAILED:
      return {
        ...state,
        markVehicleForSale: {
          data: {},
          success: false,
          error: true,
          errorMessage: action.payload,
        },
        responseDialog: {
          open: true,
          success: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    case CLEAN_SEARCH_VEHICLES:
    case SEARCH_VEHICLES:
      return {
        ...state,
        vehiclesByPlate: {
          responseDialog: initialState.responseDialog,
          error: false,
          errorMessage: '',
          list: [],
        },
      };
    case SEARCH_VEHICLES_SUCCEEDED:
      return {
        ...state,
        vehiclesByPlate: {
          responseDialog: initialState.responseDialog,
          error: false,
          errorMessage: undefined,
          list: action.payload,
        },
      };
    case SEARCH_VEHICLES_FAILED:
      return {
        ...state,
        vehiclesByPlate: {
          responseDialog: {
            open: true,
            success: false,
            errorCode: action.payload.code,
            message: action.payload.message,
            closeInConfirmation: true,
          },
          error: true,
          errorMessage: action.payload.message,
          list: [],
        },
      };
    case EXPORT_CATALOG_RECORDS:
      return {
        ...state,
        exportCatalogRecords: {
          success: false,
          loading: true,
        },
      };
    case EXPORT_CATALOG_RECORDS_SUCCEEDED:
      return {
        ...state,
        exportCatalogRecords: {
          success: true,
          loading: false,
        },
      };
    case EXPORT_CATALOG_RECORDS_FAILED:
      return {
        ...state,
        exportCatalogRecords: {
          success: false,
          loading: false,
        },
      };
    case RESET:
      return initialState;

    default:
      return state;
  }
}
